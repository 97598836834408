
.sk-v2_left-panel {
  background-color: #fff;
  min-height: calc(100vh - 80px);
  padding: 15px 0 5px;
  position: relative;
  text-transform: uppercase;
  transition: all 0.35s ease;
  overflow-x: hidden;
  white-space: nowrap;
  width: 50px;

  &.active {
    width: 200px;

    .sk-team_term-and-policy {
      opacity: 1
    }

    .main-nav {
      .sub-nav {
        display: block;
      }
    }
  }

  a {
    color: #666;

    &:hover {
      text-decoration: none;
    }
  }

  a.sk-v2_left-panel_toggle {
    display: block;
    text-align: center;

    &:hover, &.active {
      .hamburgur {
        background-position: 1px -15px;
      }
    }
  }

  .main-nav > li {
    margin: 12px 0;

    &:first-child {
      margin-top: 5px;
    }

    & > a {
      font-size: 13px;
      padding-left: 15px;

      .icon {
        height: 25px;
        margin-right: 10px;
        transition: all 0.35s ease;
        vertical-align: middle;
        width: 25px;
      }

      span {
        font-weight: 300;
      }
    }

    &:hover, &.active {

      & > a {
        color: #007bff;

        span {
          font-weight: 500;
        }
      }

      .home {
        background-position: 0 -73px;
      }

      .profile {
        background-position: 0 -138px;
      }

      .social {
        background-position: 0 -194px;
      }

      .digital {
        background-position: 0 -253px;
      }

      .enhancements {
        background-position: -126px -698px;
      }

      .analytics {
        background-position: 0 -313px;
      }

      .resources {
        background-position: 0 -371px;
      }

      .reports {
        background-position: 0 -430px;
      }

      .leads {
        background-position: 0 -486px;
      }

      .submit {
        background-position: 0 -548px;
      }

      .settings {
        background-position: 0 -607px;
      }

      .internal-property-profile {
        background-position: -152px -698px;
      }
    }
  }

  .sub-nav {
    display: none;
    font-size: 12px;
    margin-left: 50px;

    & > li {
      margin-top: 5px;

      &:hover a, &.active a {
        color: #007bff;

        .facebook {
          background-position: -36px -325px;
        }

        .instagram {
         background-position: -36px -380px;
        }

        .google-ads {
         background-position: -35px -443px;
        }

        .gmb {
         background-position: -136px -556px;
        }
      }
    }

    .icon {
      height: 16px;
      margin-right: 5px;
      vertical-align: middle;
      width: 16px;
    }
  }
}



.sk-team {
  bottom: 0;
  left: 0;
  padding: 0 5px;
  position: absolute;
  width: 100%;

  &_term-and-policy {
    display: flex;
    font-size: 8px;
    justify-content: space-between;
    margin-top: 5px;
    opacity: 0;
    padding: 0 5px;
    transition: all 0.35s ease;
  }
}
