@import "components/variables";
@import "~bootstrap/scss/bootstrap";
@import "bootstrap-select/sass/variables";
@import "bootstrap-select/sass/bootstrap-select";
@import "bootstrap-tagsinput/src/bootstrap-tagsinput";

@import "select2/dist/css/select2.min.css";

@import "daterangepicker";

@import "bootstrap_and_overrides";
@import "components/buttons";
@import "components/icons";
@import "components/mixins";

@import "partials/_card";
@import "partials/_footer";
@import "partials/_header";
@import "partials/_left-navigation";
@import "partials/_responsive";
@import "partials/_typography";

@import "login";
@import "dashboard";
@import "left_panel";
@import "bar_chart";
@import "card_progress";
@import "enhancements";
@import "form_submissions";
@import "profile";
@import "properties";
@import "reports";
@import "resources";
@import "submit";
@import "users";
@import "content_request";

/* v2 related */
@import "v2/colors";
@import "v2/common";
@import "v2/responsive";

@import "v2/home";
@import "v2/footer";
@import "v2/social";
@import "v2/paid";
@import "v2/analytics";
@import "v2/requests";
@import "v2/be";

