.footer {
  padding: 64px 0 46px;
  max-width: 1032px;

  &_content {
    .legal {
      @extend .d-flex, .justify-content-between;

      margin: 0 auto 36px;
      width: 328px;

      &_column {
        
      }

      a {
        @extend .text-white, .mx-2;
        margin-bottom: 16px;
        display: block;
      }
    }
  }

  &_bottom {
    @extend .d-flex, .justify-content-between;

    border-top: 1px solid #D0CBBD;
    padding-top: 1rem;

    p {
      color: #F6F3EE;
      margin-bottom: 0;
    }
  }

  .powred-by {
    @extend .text-center;

    margin-bottom: 24px;
  }
}

.social-icons {
  display: flex;

  .icon {
    height: 1.25rem;
    stroke: #fff;
    width: 1.25rem;
  }

  a {
    margin-right: 20px;
    color: #333;

    &:hover {
      color: #000;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
