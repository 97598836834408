.sk-login-wrapper {
  height: 100vh;

  .sk-login {
    @extend .d-flex;

    background-color: #00558D;

    &_welcome-graphics {
      background-image: url("~login-dashboard-animation.gif");
      background-position-x: -200px;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
      width: calc(100% - 458px);

      .login-video {
        height: 0;
        display: none;
        max-width: 100%;
        overflow: hidden;
        width: 0;
      }
    }

    &_form-wrapper {
      background-color: #F6F3EE;
    }

    &_form {
      height: 100vh;
      overflow: auto;
      padding: 80px 60px 0;
      width: 458px;

      &_logo-wrapper {
        @extend .text-center;

        margin-bottom: 48px;
      }

      .btn {
        font-size: 16px;
      }
      
      .password {
        position: relative;

        .icon {
          cursor: pointer;
          position: absolute;
          right: 18px;
          top: 41px;
          stroke: #aaa;
        }        
      }
    }

    &_title {
      font-size: 16px;
      margin-bottom: 24px;
    }

    &_status-message {
      border-bottom: 0.1rem solid #6a86a7;
      padding-bottom: 1rem;
    }

    .form-group {
      label {
        padding-left: 10px;
      }
    }

    .form-control {
      background-color: #fff;
      border-color: transparent;
      border-radius: 5px;
      font-size: 12px;
      height: 48px;
      padding: 10px;
    }

    button {
      color: #F6F3EE;
      cursor: pointer;
      font-size: 16px;
      height: 44px;
      font-weight: 400;
    }

    .btn-primary{
      @extend .btn-block;
    }

    .sk-login_form_logo {
      width: 188px;
    }

    form {
      padding: 5px 0 20px;
      margin-bottom: 20px;
    }

    .form-group {
      margin-bottom: 16px;
    }

    &.reset-password {
      form {
        border-bottom: none;
      }
    }

    .remember-and-forget {
      @extend .d-flex, .justify-content-between;

      margin-bottom: 1.5rem;

      label span, label input {
        vertical-align: middle;
      }
    }

    .back {
      display: block;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 32px;
    }
  }
}

.login-button-group {
  .btn {
    border-radius: 6px;
    line-height: 28px;
  }

  .facebook-login {
    width: 235px;
  }

  .login {
    width: 124px;
  }
}

.sk-login-wrapper .sk-login .forgot-password {
  border-radius: 6px;
  justify-content: center;
}

.login-form, .forgot-password-form {
  .btn {
    @extend .btn-secondary;

    height: 44px;
    width: 100%;

    &.btn-primary {
      background-color: #00A9DF;
      border-color: #00A9DF;
    }
  }

  &:valid {
    .btn {
      @extend .btn-info;

      &:hover {
        background-color: #8CD4E6;
        border-color: #8CD4E6;
      }
    }
  }
}

.form-group.password {
  .eye-open {
    display: none;
  }
  .eye-close {
    display: inline-block;
  }

  &.visible {
    .eye-open {
      display: inline-block;
    }
    .eye-close {
      display: none;
    }
  }
}

.sessions_new .flash.alert-success {
  width: 400px;
  left: calc(((100% - 458px)/2) - 200px);
  top: 120px;
  background-color: #fff !important;
  border-radius: 10px;
  padding: 10px;
  font-weight: 700;
  color: #17568D !important;
  button {
    display: none;
  }
}

.form-with-error {
  .error-message {
    color: red;
  }

  .form-control{
    border: 1px solid red !important;
  } 
}


.sk-copy {
  color: #666;
  margin-top: 80px;
}
