// https://scotch.io/tutorials/how-to-use-sass-mixins

@mixin center {
  // write the css here
  text-align: center;
}

@mixin boosted-badge-container {
  .boosted-badge-container {
    background-color: #55C2B8;
    height: 50px;
    right: -25px;
    position: absolute;
    top: -25px;
    width: 50px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);

    .boosted-badge {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      position: absolute;
      top: 18px;
      right: 35px;
    }
  }
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -7px;
  padding-right: 10px;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 10px;
    height: 10px;
    margin-top: 0.2em;
    background: $bgColor;
  }
}
