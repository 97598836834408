@import "./colors.scss";

@import url("https://p.typekit.net/p.css?s=1&k=iqt6sdo&ht=tk&f=47343&a=189390494&app=typekit&e=css");

@font-face {
  font-family:"alkaline";
  src:url("https://use.typekit.net/af/32f0f8/00000000000000007735e9fa/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/32f0f8/00000000000000007735e9fa/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/32f0f8/00000000000000007735e9fa/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

html, body {
    background-color: #00558D;
    letter-spacing: 0;
}


/* grabbed from v1 common */
a:hover {
  text-decoration: none;
}

.page-header {
  margin-bottom: 15px;

  .page-title {
    margin-bottom: 0;
  }
}

h4, .h4 {
  color: #141C24;
  font-size: 1rem;
  font-weight: 600;
}

.font-base {
  font-size: 12px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-bold {
  font-weight: 700 !important;
}

.page-title {
  &_icon {
    vertical-align: middle;
    margin-right: 5px;
  }

  a {
    font-size: 14px;
    font-weight: normal;
    color: #666;
    display: inline-block;
    padding: 10px;
  }
}

.section-title, .area-title {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 16px;

  img {
    height: 16px;
    margin-right: 5px;
  }
}

.blue-text {
  color: #00adee;
}

.statistic {
  background-color: #00ADEE;
  color: $white;
  padding-bottom: 7.5rem;
  padding-top: 7.5rem;
  text-align: center;
  &_values {
    margin-bottom: 5rem;
    margin-top: 5rem;
  }
  &_value {
    font-size: 2rem;
    font-weight: 500;
    margin: 2.5rem 0 1.5rem;
  }
  .line-btn {
    border-color: $white;
    color: $white;
  }
}

.download-leads {
  margin: 0 0 15px;
}

.our-story {
  padding-bottom: 7rem;
  padding-top: 7rem;

  h2 {
    font-size: 24px;
    margin-bottom: 2rem;

    span {
      border-bottom: 0.15rem solid #00adef;
      display: inline-block;
    }
  }
}

.main-container_primary-panel {
  width: 1032px;
}

.contact-info {
  padding-bottom: 84px;
  padding-top: 84px;

  .icon {
    height: 48px;
    width: 48px;

    &.location {
      background-position: 1.4rem -12.8rem;
    }

    &.phone {
      background-position: -7.2rem -9.3rem;
    }

    &.email {
      background-position: -7.6rem -12.8rem;
    }
  }
}

.contact-form {
  padding-bottom: 84px;

  h2 {
    font-size: 24px;
    margin-bottom: 2rem;

    span {
      border-bottom: 0.15rem solid #00adef;
      display: inline-block;
    }
  }

  .form-control {
    border-color: #888;
    border-width: 0 0 0.15rem;
    padding: 1rem 0;
  }

  select.form-control {
    height: auto !important;
  }

  #messagetoteam {
    margin-bottom: 4rem;
  }
}

.primary-header {
  font-size: 2.5rem;
  font-weight: 600;
  text-transform: uppercase;
  &_hilighted {
    color: #00ADEE;
    font-size: 3.3rem;
    text-transform: none;
  }
}

.secondary-header {
  font-size: 2.2rem;
  font-weight: 500;
}

.learn-more-btn {
  border: 0.15rem solid $white;
  color: $white;
  display: inline-block;
  font-weight: 500;
  padding: 0.6rem 1.5rem;
  text-transform: uppercase;
  transition: all 0.35s ease;

  &:hover {
    background-color: $white;
    color: #333;
    text-decoration: none;
  }
}

.table {
  margin-bottom: 0;
}

.contact-us-btn {
  border: 0.15rem solid #302424;
  color: #302424;
  display: inline-block;
  font-weight: 500;
  margin: 2rem 0;
  padding: 1.2rem 2.5rem;
  text-transform: uppercase;
  transition: all 0.35s ease;

  &:hover {
    background-color: #302424;
    color: $white;
    text-decoration: none;
  }
}
.footer-fb-logo {
  display: inline-block;
  margin: 2rem 0;
}

.table .center-middle {
  text-align: center;
  vertical-align: middle;
}
.full-height {
  background-color: #00558D;
}

.transparent-btn {
  background-color: transparent;
  border: 0.15rem solid #000;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  transition: all 0.35s ease;

  &:hover {
    border-color: #00adef;
    color: #00adef;
    text-decoration: none;
  }
}
.line-btn {
  background-color: transparent;
  border-bottom: 0.15rem solid #000;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.3 0;
  text-transform: uppercase;
  transition: all 0.35s ease;

  &:hover {
    border-color: #00adef;
    color: #00adef;
    text-decoration: none;
  }
}

.sk-transparent-button {
  background-color: transparent;
  border: 0.1rem solid #6a86a7;
  color: #6a86a7;
  margin: 1rem 0;
  padding: 0.5rem 0;

  &:hover {
    color: $white;
    text-decoration: none;
  }
}

.clear-button-style {
  background-color: transparent;
  border: none;
}

.faq {
  p.not-associated {
    font-size: 20px;
    margin-top: 200px;
    line-height: 30px;
    color: #fff;
  }
}

/******/
// scroll purpose
.purchased-service, .enhancement-history {
  &_campaigns {
    max-height: 285px;
  }
}

.property-profile .enhancement-history {
  &_campaigns {
    max-height: 440px;
  }
}

.enhancement-history {
  &_campaigns {
    .area-title {
      margin-bottom: 3px;
    }

    &_list {
      &_no_data {
        padding-top: 10px;
      }
      &_item {
        background-color: #e7e7e7;
        border-radius: 5px;
        padding: 5px 10px;
        margin: 10px 0;

        .campaign-title {
          font-size: 12px;
          max-width: calc(100% - 75px);
          margin: 0 5px 0 0;
          overflow: hidden;
          text-overflow: ellipsis;
          text-transform: uppercase;
          white-space: nowrap;
        }

        .campaign-status {
          color: #868585;
          display: inline-block;
          font-size: 11px;
          width: 75px;

          .status {
            border-radius: 50%;
            display: inline-block;
            height: 8px;
            width: 8px;

            &.active {
              background-color: #21d195;
            }

            &.completed {
              background-color: #e07385;
            }
          }
        }

        table {
          margin-top: 5px;
          width: 100%;

          thead {
            color: #868585;
          }

          td {
            vertical-align: top;
          }
        }
      }
    }
  }

  .enhancement-history-info {
    margin-right: 20px;
  }
}

.photo-thumbnail {
  &_container {
    background-color: rgb(145, 145, 138);
    border-radius: 50%;
    display: inline-block;
    height: 36px;
    overflow: hidden;
    width: 36px;

    .photo {
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.thick-line {
  height: 0;
  line-height: 0;
  margin: 5px 0;
  position: relative;

  &::after {
    background-color: #ccc;
    border-radius: 3px;
    content: " ";
    display: inline-block;
    height: 2px;
    left: calc(50% - 14px);
    position: absolute;
    top: 0;
    width: 28px;
  }

  &.red {
    &::after {
      background-color: #ef92a2;
    }
  }

  &.blue {
    &::after {
      background-color: #00adee;
    }
  }
}

.daterangepicker {
  box-shadow: #cccccc -5px 5px 8px;
  .show-calendar {
    width: 630px;
    right: 0;
    left: auto;

    .cancelBtn {
      background: transparent;
      border: 1px solid $grey;
    }

    .applyBtn {
      background: $primaryBtn;
      border-color: $primaryBtn;
    }

    .drp-selected {
      float: left;
      padding-top: 8px;
      margin-left: 150px;
      font-size: 14px;
      letter-spacing: 1px;

      span {
        color: $primaryBtn;
        font-weight: 500;
      }

      em {
        color: $grey;
      }
    }
  }
}

.privacy-terms {
  a {
    color: #fff;
    font-size: 10px;
    display: inline-block !important;
    margin-left: 17px;
    text-transform: none;

    &:last-child {
      margin-left: 30px;
    }
  }
}

.forced-hidden {
  display: none !important;
}

.sk-main-container {
  &_header {
    @extend .d-flex, .justify-content-between, .align-items-center;

    &_date-picker {
      border: 1px solid #D0CBBD;
      border-radius: 0.25rem;
      height: 34px;
    
      &:focus, &:focus-visible {
        border-color: #007bff;
        outline: none;
      }
    }
  }
}

.back {
  color: #00558D;

  .icon {
    height: 1rem;
    margin-right: 1rem;
    stroke: #00558D;
    width: 1rem;
  }
}

.accordion_title {
  cursor: pointer;
}

.text-link {
  font-style: italic;
  text-transform: capitalize;

  &.gray {
    color: #555;
  }

  &:hover {
    color: #007bff;
  }
}

.main-container_right-panel {
  .page-title {
    font-size: 32px;
    color: #141C24;
    font-weight: bold;
  }
}

.flash.alert {
  border-radius: 10px;
  position: absolute;
  text-align: center;
  width: 450px;
  z-index: 999;
  left: calc(50% - 225px);
  top: 80px;
  color: #fff !important;
  background-color: #17568D !important;
  font-weight: 500;
}

.chart-dropdown-handler {
  float: right;
  border: 1px solid $cardHeader;
  color: $cardHeader;
  border-radius: 15px;
  padding: 2px 10px;
  &:hover {
    color: $cardHeader;
  }
}

.gray {
  color: #535657;
}


/* nav styling with react select2*/
.select-property{
  
  .css-1s2u09g-control { 
    background: none;
    border: none;

    .css-14el2xx-placeholder {
      color: #fff;
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: 500;
    }

    .css-1okebmr-indicatorSeparator {
      background: none;
    }

    .css-tlfecz-indicatorContainer {
      color: #fff;
    }
  }
}

/* react select input size - enhancements page*/
.select-ad-set-id {
  font-size: 14px;
  text-transform: uppercase;
}

.card.type, .card.highlights {
  width: 284px;
}

#reportrange {
  .month-select {
    padding: 5px;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 5px;

    option {
      margin-bottom: 3px;
    }
  }
}
/* end from v1 common*/

a {
    color: #00558D;
    text-decoration: none !important;
  }

h3, .h3, h2, .h2 {
    font-size: 24px;
}

button:focus {
    outline: none;
}

.btn {
    align-items: center;
    border-radius: 16px;
    display: inline-flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    height: 34px;

    &.btn-primary {
      background-color: #00558D;
      border-color: #00558D;
    }

    &.btn-secondary {
      background-color: #91918A;
      border-color: #91918A;
    }

    &.beach-view {
        background-color: #00A9DF;
        border-color: #00558D;
        color: #fff;
    }

    &.kapture-blue {
        background-color: #00558D;
        border-color: #fff;
        color: #fff;
    }

    &.fire-pit {
        background-color: #FF9900;
    }

    &.dark-gray {
        background-color: #535657;
    }

    &.white {
        color: #535657;
        background-color: #fff;
        border-color: #535657;

        .icon {
            stroke: #535657;
        }
    }

    &.btn-outline-primary {
        color: #141C24;

        &.beach-view {
            border-color: #00A9DF;
            background-color: transparent;
        }

        &.kapture-blue {
            border-color: #00558D;
            background-color: transparent;
        }

        &.fire-pit {
            border-color: #FF9900;
            background-color: transparent;
        }

        &.dark-gray {
            border-color: #535657;
            background-color: transparent;
        }

        &:hover {
            background-color: #eff4f7;
            background-color: transparent;
        }
    }
}

.btn-primary {
    color: #fff;
    background-color: #00adee;
    border-color: #00adee;
}

.btn.disabled {
    background-color: #91918A;
}

.main-container {
    &_no-left-nav {
        background-color: #F6F3EE;
        height: calc(100vh - 72px);
        padding-top: 40px;
        overflow: auto;

        .footer {
            background-color: #00558D;
            margin: 0;
            max-width: 100%;

            &_content {
                margin: 0 auto;
                max-width: 1032px;
            }
        }
    }
}

.edit_property {
    .form-check {
        margin-bottom: 16px;

        label {
            margin-left: 24px;
        }
    }

    .form-check-input {
        border: none;
        height: 0;
        position: relative;
        width: 0;

        &::before, &::after {
            border-radius: 50%;
            content: " ";
            height: 12px;
            left: 0;
            position: absolute;
            top: -0.8em;
            width: 12px;
        }

        &::before {
            background-color: #fff;
            border: 1px solid #00558D;
        }

        &:checked {
            &::after {
                background-color: #00558D;
            }
        }

        &#property_alert_instagram {
            &::before {
                background-color: #fff;
                border: 1px solid #E13B56;
            }
    
            &:checked {
                &::after {
                    background-color: #E13B56;
                }
            }
        }

        &#property_alert_google {
            &::before {
                background-color: #fff;
                border: 1px solid #FF9900;
            }
    
            &:checked {
                &::after {
                    background-color: #FF9900;
                }
            }
        }

        &#property_alert_pixel {
            &::before {
                background-color: #fff;
                border: 1px solid #00A9DF;
            }
    
            &:checked {
                &::after {
                    background-color: #00A9DF;
                }
            }
        }

        &#property_alert_facebook {
            &::before {
                background-color: #fff;
                border: 1px solid #00558D;
            }
    
            &:checked {
                &::after {
                    background-color: #00558D;
                }
            }
        }

        &#property_alert_other {
            &::before {
                background-color: #fff;
                border: 1px solid #141C24;
            }
    
            &:checked {
                &::after {
                    background-color: #141C24;
                }
            }
        }
    }
}

.total-items {
    background-color: #fff;
    color: #666;
    padding: 12px 16px 10px;
    border-radius: 0 0 12px 12px;
}

.navbar.bg-light {
    background-color: #fff !important;
}

.card {
    .card-header {
        border-bottom: 1px solid #141C24;
        border-radius: 8px 8px 0 0;
        color: #141C24;
        font-size: 16px;
        padding: 18px 28px 18px 32px;

        &.paid-current {
          background-color: #8CD4E6;
        }

        &.paid-recommended {
          background-color: #00A9DF;
        }

        &.kapture-blue {
            background-color: #00558D;
            color: #fff;
        }

        &.beach-view {
            background-color: #00A9DF;
        }

        &.coral {
            background-color: #F86A8D;
        }

        &.fire-pit {
            background-color: #FF9900;
        }

        &.lounge {
            background-color: #FFA8B0;
        }

        &.pale-yellow {
            background-color: #F6DA82;
        }

        &.south-facing {
          background-color: #F6DA82;
        }
    }

    .card-container, .card-container_item {
        & > span {
            font-size: 24px;
            font-weight: 500;
        }
    }
}

.local-packages, .paid-content_tabs, .analytics-content_tabs {
    .nav {
        justify-content: space-between;

        &.nav-tabs {
            position: relative;
            bottom: -1px;
        }
    }

    .nav-item {
        background-color: transparent;
        border-radius: 12px 12px 0 0;
        border-style: solid;
        border-width: 2px 2px 0;
        bottom: -1px;
        margin-top: 0.5rem;
        overflow: hidden;
        position: relative;
        z-index: 1;

        &::after {
            background-color: transparent;
            bottom: -1px;
            content: " ";
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            right: 0;
        }

        .line-chart-link {
            color: #141C24;

            .card_value {
                color: #141C24;
                margin-bottom: 0;
            }

            &.active {
                color: #00558D;
                font-size: 16px;

                .growth {
                    font-size: 12px;
                    color: #535657;
                }

                .card_value {
                    color: #00558D;
                    font-weight: 500;
                }
            }
        }

        .card_value {
            font-size: 24px; 
        }
    }

    .tab-content-wrapper {
        background-color: #fff;
        border-radius: 0 0 1rem 1rem;
        border-style: solid;
        border-width: 2px;
        padding-bottom: 19px;

        .tab-content {
            padding: 24px 16px;
            height: 312px;
        }

        .chart-handler {
            align-items: center;
            display: flex;
            justify-content: center;

            a {
                margin: 0 1rem;
                text-align: center;
            }
        }
    }
}

.users_settings {
    .main-containe_primary-panelr-wrapper{
        width: 100%;
    }
}

.rounded-border {
  border-radius: 10px;
  overflow: hidden;
}

.social-link-icon-wrapper {
    align-items: center;
    border-radius: 0.5rem;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;  
    
    .icon {
      height: 20px;
      stroke: #141C24;
      width: 20px;
    }

    &.facebook {
      background-color: #8CD4E6;

      &.area-line {
        background-color: #fff;
        border: 1px solid #8CD4E6;
      }
    }

    &.instagram {
      background-color: #FFA8B0;
      &.area-line {
        background-color: #fff;
        border: 1px solid #8CD4E6;
      }
    }

    &.dropbox {
      background-color: #F6DA82;
      &.area-line {
        background-color: #fff;
        border: 1px solid #8CD4E6;
      }
    }
}

.bootstrap-tagsinput {
  width: 100%;
  
  .tag {
    margin-right: 2px;
    color: white;
    background-color: #ccc;
    padding: 5px;
    border-radius: 10px;
  }
}

.photo-thumbnail {
  min-width: 36px;
  width: 36px;
}

.photo-thumbnail_container {
  background-color: #D5CFC6;
  border-radius: 50%;
  display: inline-block;
  height: 36px;
  width: 36px;

  img {
    vertical-align: inherit;
  }
}

/**** custom select *****/

/* search bar icon style */
.search-and-notification {
  position: relative;

  .nav-search {
    height: 16px;
    position: absolute;
    stroke: #91918A;
    stroke-width: 2px;
    top: 15px;
    right: 15px;
    width: 16px;
  }
}

.search-and-notification {
  .dropdown.notification {
      display: none;
  }
}

.bootstrap-select, .select-property {
  background-color: #fff;
  height: 36px;

  .btn.dropdown-toggle.btn-light {
    background-color: #fff;
    border-radius: 0;
    border: none !important;
    outline: none !important;

    &:focus, &:focus-visible, &:hover, &:active {
      border: none !important;
      outline: none !important;
    }

    &::after {
      display: none;
    }
  }

  .filter-option-inner-inner {
    align-items: center;
    display: flex;
    color: #00558D;
    font-size: 16px;
    font-weight: 500;

    span:nth-child(2) {
      display: inline-block;
      /*max-width: calc(100% - 6em);
      text-overflow: ellipsis;
      overflow: hidden;*/
    }
  }

  .photo-thumbnail_container {
    height: 48px;
    margin-right: 16px;
    width: 48px;
  }

  .dropdown-menu {
    border-color: #D0CBBD;
    border-style: solid;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

    &.inner {
      & > li {
        margin: 2px 7px;

        .dropdown-item {
          padding: 4px 16px;
        }
      }
  
      .text {
        align-items: center;
        color: #141C24;
        display: flex;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .dropdown-item.active, .dropdown-item:active {
    background-color: #EDE6DC;
    border-radius: 6px;

    .text {
      font-weight: 700;
    }
  }
}

.btn.dropdown-toggle.btn-light {
  background-color: #fff;
  border: none;
  outline: none;

  &:hover {
    border: none;
    outline: none;
  }
}

.bootstrap-select .dropdown-menu {
  font-size: 12px;
}

.gauge-chart {
  height: 200px;
}


.generating {
  position: relative;
  cursor: not-allowed;

  &::after, &::before {
      content: " ";
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      bottom: -7px;
  }

  &::after {
      background-color: #3498db;
      animation: bounce 0.3s infinite alternate;
      left: calc(50% + 2px);
  }

  &::before {
      background-color: #0368ac;
      animation: bounce 0.6s infinite alternate;
      left: calc(50% - 8px);
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translatex(0);
      transition: all 0.25s ease;
    }
    40% {
      transform: translatex(40%);
      background-color: #7cb4f3;
    }
    60% {
      transform: translatex(100%);
      background-color: #0368ac;
    }
  }
}

.btn.generating  {
  position: relative;

  &::after, &::before {
    animation: none;
    border-radius: 16px;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }

  &::before {
    background-color: #fff;
    border-color: #fff;
    outline: solid 2px #fff;
  }

  &::after {
    animation: rotate 0.8s infinite;
    background-color: #fff;
    border-color: #00558D #EDE6DC #EDE6DC #EDE6DC;
    border-style: solid;
    border-width: 3px;
    height: 26px;
    left: calc(50% - 13px);
    width: 26px;
    top: 2px;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
      transition: all 0.35s ease;
    }

    100% {
      transform: rotate(720deg);
    }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translatex(0);
      transition: all 0.25s ease;
    }
    40% {
      transform: translatex(0);
      background-color: #7cb4f3;
    }
    60% {
      transform: translatex(0);
      background-color: #0368ac;
    }
  }
}
