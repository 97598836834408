body {
  background-color: #F6F3EE;
  color: #141C24;
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

table {
  font-size: 12px !important;
}

b, strong {
  font-weight: 600;
}

.navbar {
  margin-bottom: 2.2rem;
  position: relative;
  z-index: 9;

  .navbar-nav {
    align-items: center;
    margin: 0 auto;
    width: 30rem;

    .nav-item {
      margin: 0;
    }

    .nav-link {
      border-bottom: 3px solid transparent;
      color: $white;
      font-size: 0.9rem;
      font-weight: 500;
      padding-bottom: 0;
      text-transform: uppercase;
      transition: all 0.35s ease;

      &:hover {
        border-color: #00ADEE;
        color: $white;
      }
    }
  }
}

.nav-link.hihe-on-small-screen {
  background-color: #00ADEE;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}

.navbar.navbar-dashboard {
  margin-bottom: 0;

  .navbar {
    &.bg-dark {
      background-color: #506b88 !important;
    }
  }

  .dropdown-menu {
    left: auto;
    right: 0;
    text-align: right;
  }
}

.btn {
  //border: none;
  border-radius: 15px;
  font-size: 12px;
  padding: 0.15rem 0.85rem;

  &.btn-sm {
    font-size: 12px;
    line-height: 20px;
    height: 28px;
  }
}

.btn-primary, .progress-bar {
  background-color: #00558D;
}

.btn-danger, .bg-danger {
  background-color: #f398a7;
}

.btn-success {
  background-color: #84DDAE;

  &:hover {
    background-color: #00558D;
  }
}

.btn-info {
  background-color: #00A9DF;
}

.btn-outline-light {
  border: 1px solid #F6F3EE;

  &:hover {
    background-color: transparent;
  }
}

.form-control {
  background-color: #eee;
  border: none;
  font-size: 12px;
  height: 36px;
}

.form-group.field-with-error .form-control {
  border: 1px solid #dc3545 !important;
}

textarea.form-control {
  height: 100px;

  &.long-text {
    height: 300px;
  }
}

.table {
  background-color: $white;
}

.progress-bar {
  background-color: #00acec;
}

.table {
  thead th {
    border: none;
    color: #888;
    font-weight: normal;
  }

  td {
    border: none;
    vertical-align: middle;
  }

  &.newtwork-breakdown {
    th, td {
      padding: 10px 0;
      text-align: center;
    }
  }

  &.performance-by-campaign {
    height: 184px;
    
    th, td {
      text-align: center;
    }
  }
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.tooltip-inner {
  max-width: 100% !important;
}

/* override bootstrap select */
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100%;
}

.modal-content {
  border: 12px;
}
