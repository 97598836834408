.sk-left-panel {
  height: calc(100vh - 4.6rem);
  font-size: 0.9rem;
  padding-top: 1rem;
  text-transform: uppercase;
  .slect-company {
    margin-bottom: 1rem;
    .dropdown-toggle {
      background-color: transparent;
      color: $white;
      display: block;
      padding: 0.4rem 0;
      position: relative;
      &:hover {
        text-decoration: none;
      }
      &:active {
        outline: none;
      }
      &::after {
        border-width: 0.45rem;
        position: absolute;
        right: 0;
        top: 1.1rem;
      }
    }
    .dropdown-menu {
      background-color: #a8bfd7;
      border-radius: 0;
      max-height: 80vh;
      overflow: auto;
      width: 100%;
    }
  }
  .sk-left-panel_nav_clickable-date-range {
    border-bottom: 0.1rem solid #607993;
    border-top: 0.1rem solid #607993;
    margin: 0 -15px 1rem;
    padding: 1rem 15px;
    span {
      color: $white;
    }
  }
  &_nav {
    background-color: $LightSteelBlue;
    border-right: 1px solid #fff;
    margin: 0 -1rem;
    a, &_cat-link {
      color: #fff;
      display: block;
      transition: all 0.35s ease;
      &:hover {
        text-decoration: none;
        .sk-left-panel_nav_sub_icon-fb {
          background-position: 0.5rem -50.75rem;
        }
        .sk-left-panel_nav_sub_icon-twitter {
          background-position: -0.8rem -50.75rem;
        }
        .sk-left-panel_nav_sub_icon-instagram {
          background-position: -2.5rem -50.75rem;
        }
        .sk-left-panel_nav_sub_icon-google-ad {
          background-position: -4rem -50.8rem;
        }
      }
    }
    &_cat-link {
      padding: 1rem;
      &:hover, &.active {
        background-color: $LightSteelBlue;
        color: $white;
        position: relative;
        &::before {
          background-color: #01c4f0;
          content: " ";
          display: inline-block;
          height: calc(100% - 0.5rem);
          left: 0;
          margin: 0.25rem 0;
          position: absolute;
          top: 0;
          width: 0.5rem;
        }
      }
    }
    &_sub {
      a {
        color: #819ebe;
        padding: 0.5rem 0 0.5rem 3.125rem;
        text-transform: capitalize;
        &:hover, &.active {
          background-color: $white;
          color: #666;
        }
      }
      .icon {
        height: 1.5rem;
        vertical-align: middle;
        width: 1.5rem;
      }
    }
    &_clickable-date-range {
      span {
        color: #ccc;
        display: inline-block;
        margin-top: 4px;
        text-align: center;
        width: calc(100% - 2rem);
      }
      a {
        color: #fff;
        display: inline-block;
        font-size: 20px;
        width: 1rem;
        &:first-child {
          float: left;
        }
        &:last-child {
          float: right;
        }
        &.disabled {
          color: #ddd;
        }
      }
    }
  }
}
