.paid-content {
    &_header {
        align-items: center;
        display: flex;
        background-color: #00558D;
        border-radius: 1rem;
        color: #fff;
        justify-content: space-between;
        margin: 0 0 40px 0;
        padding: 16px 62px 17px 88px;

        &_info {
            width: calc(100% - 38rem);
        }

        &_block {
            background-color: #fff;
            border: 2px solid transparent;
            border-radius: 1rem;
            color: #141c24;
            height: 10.5rem;
            padding: 1rem;
            width: 12.5rem;

            &_top {
                align-items: flex-start;
                display: flex;
                justify-content: space-between;
                margin-bottom: 1rem;

                .summary-ad-icon {
                    margin-top: 8px;
                }

                .icon {
                    stroke: #91918A;
                }
            }

            &.meta {
                border-color: #00A9DF;
            }

            &.google {
                border-color: #FF9900;
            }

            &_budget {
                color: #91918A;

                &.current {
                    color: #141C24;
                }

                strong {
                    font-size: 24px;
                }
            }
        }
    }
}

.paid-content_tabs {
    margin-bottom: 40px;

    .nav-tabs .nav-item {
        width: calc(16.66% - 4px);
    }

    .nav-item {
        &.Clicks {
            border-color: #00A9DF;
        }

        &.Impressions {
            border-color: #8CD4E6;
        }

        &.CTR {
            border-color: #F6DA82;
        }

        &.CPC {
            border-color: #FF9900;
        }

        &.Conversions {
            border-color: #f86a8d;
        }

        &.Spend {
            border-color: #141c24;
        }
    }

    .active-tab-Clicks {
        .nav-item.Clicks {
            background-color: #fff;
            margin-top: 0;

            &::after {
                background-color: #fff;
            }
        }

        .tab-content-wrapper {
            border-color: #00A9DF;
        }
    }

    .active-tab-Impressions {
        .nav-item.Impressions {
            background-color: #fff;
            margin-top: 0;

            &::after {
                background-color: #fff;
            }
        }

        .tab-content-wrapper {
            border-color: #8CD4E6;
        }
    }

    .active-tab-CTR {
        .nav-item.CTR {
            background-color: #fff;
            margin-top: 0;

            &::after {
                background-color: #fff;
            }
        }

        .tab-copaid-content_headerntent-wrapper {
            border-color: #F6DA82;
        }

        .tab-content-wrapper {
            border-color: #F6DA82;
        }
    }

    .active-tab-CPC {
        .nav-item.CPC {
            background-color: #fff;
            margin-top: 0;

            &::after {
                background-color: #fff;
            }
        }

        .tab-content-wrapper {
            border-color: #FF9900;
        }
    }

    .active-tab-Conversions {
        .nav-item.Conversions {
            background-color: #fff;
            margin-top: 0;

            &::after {
                background-color: #fff;
            }
        }

        .tab-content-wrapper {
            border-color: #f86a8d;
        }
    }

    .active-tab-Spend {
        .nav-item.Spend {
            background-color: #fff;
            margin-top: 0;

            &::after {
                background-color: #fff;
            }
        }

        .tab-content-wrapper {
            border-color: #141c24;
        }
    }
    .industry-avg strong {
      margin-left: 10px;
    }

    .legend {
      display: flex;
      justify-content: space-between;
      span {
        display: inline-block;
      }
      width: 150px;
      position: relative;
      left: calc(50% - 75px);
      .meta, .google {
        .line {
          border-top: 2px solid #ff9933;
          padding: 0 10px;
          margin-right: 10px;
          vertical-align: middle;
        }
      }
      .meta .line {
        border-top: 2px solid #00A9DF;
      }
    }

    .industry-avg {
      margin-top: -15px;;
    }
}

.meta-ads {
  margin: 60px 0 40px;



  .carousel-control-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 6px 0 24px;
  
    .carousel-control-prev, .carousel-control-next, .carousel-indicators {
      position: static;
    }

    .carousel-indicators {
      & > li {
        border-radius: 50%;
      }
    }
  
    .carousel-control-prev, .carousel-control-next {
      background-color: #fff;
      border-radius: 50%;
      height: 33px;
      position: relative;
      transition: all 0.35s ease;
      width: 32px;
  
      .icon {
        height: 11px;
        stroke-width: 0.18rem;
        width: 11px;
      }
  
      &:hover {
        background-color: #fff;
        cursor: pointer;
      }
    }
  
    /*.carousel-control-prev {
      &::before {
        transform: rotate(45deg);
      }
    }*/
  
    .carousel-indicators {
      margin: 0 1rem;
  
      li {
        background-color: #D0CBBD;
        height: 0.6rem;
        margin: 0 0.5rem;
        padding: 0;
        width: 0.6rem;
      }
  
      .active {
        background-color: #007BFF;
      }
    }
  
    button {
      border: none;
    }
  
    .carousel-indicators {
      button {
        background-color: #ede6dc;
        border-radius: 50%;
        height: 0.6rem;
        margin: 0 0.25rem;
        width: 0.6rem;
  
        &:active {
          background-color: #007BFF;
        }
      }
    }
  }

  .carousel-inner {
    padding: 0 1px;
  }

  .cards {
      display: flex;
      justify-content: start;
      padding: 1px;
      margin-left: 1px;
      width: 100%;

      .meta-ads_article {
          .card-header {
              background-color: #00558D;
              color: #fff;
          }
      }

      .card {
          max-width: 305px;
          min-width: 305px;
          margin: 0 0 32px;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
      }
  }

  &_article {
    .preview-container {
      height: 430px;
      overflow: hidden;

      iframe {
        zoom: 0.8; 
        -moz-transform: scale(0.8); 
        -moz-transform-origin: 0 0;
      }
    }

    &.zoomed {
      .card {
        max-width: 375px;
        min-width: 375px;
      }
      .preview-container {
        height: 610px;

        iframe {
          zoom: 1; 
          -moz-transform: scale(1); 
          -moz-transform-origin: 0 0;
        }
      }

      .card-header {
        background-color: #00A9DF !important;
        font-weight: 700;
        color: #141C24 !important;
      }
    }
  }  
}

.current-and-recommended {
    margin-bottom: 2rem;

    .nav-item {
        background-color: #d0cbbd;
        border-radius: 1rem 1rem 0 0;
        border-color: #d0cbbd;
        border-style: solid;
        border-width: 2px 2px 0;
        bottom: -1px;
        color: #141C24;
        margin-bottom: -2px;
        margin-top: 0.5rem;
        margin-right: 0.5rem;
        overflow: hidden;
        position: relative;
/*
        &::after {
            background-color: transparent;
            bottom: -1px;
            content: " ";
            display: block;
            height: 2px;
            left: 0;
            position: absolute;
            right: 0;
        }
*/
        .nav-link {
            background-color: #D0CBBD;;
            border: none;
            color: #141C24;
            padding: 1rem 3rem;
            font-size: 120%;
            transition: all 0.35s ease;

            &:hover {
              cursor: pointer;
            }    

            &.active {
                background-color: #f6f3ee;
                font-weight: bold;
                position: relative;

                &::after {
                    background-color: #F6F3EE;
                    bottom: -2px;
                    content: " ";
                    display: block;
                    height: 4px;
                    left: 0;
                    position: absolute;
                    right: 0;
                }
            }
        }
    }

    .tab-content {
        border: 2px solid #d0cbbd;
        border-radius: 0 12px 12px 12px;
        padding: 0 41px;

        &_section {
            align-items: center;
            visibility: hidden;
            height: 0;
            display: flex;
            justify-content: space-around;

            &.active {
                max-width: 100%;
                min-height: 300px;
                visibility: visible;
                width: 100%;
            }
        }
        
        .card {
            border: 1px solid #141c24;
            padding: 0;
            text-align: left;

            .card-container {
                padding: 13px 28px 13px 32px;
            }
        }
    }

        /*
    .active-tab-Spend {
        .nav-item.Spend {
            background-color: #fff;
            margin-top: 0;
            padding-top: 0.5rem;

            &::after {
                background-color: #fff;
            }
        }

        .tab-content-wrapper {
            border-color: #d0cbbd;
        }
    }
        */
}

.paid-chart-and-data {
    margin: 0;
    padding: 0;

    &_item {
        margin: 0;
        width: calc(33.33% - 17px);
    }

    &_chart-container {
        margin: 0 54px;
    }
}

.table.google-keywords-table {
    border-radius: 12px;
    margin-top: 24px;
}

.insights-by-time {
    &_header {
        @extend .d-flex, .justify-content-between;

        &_cell {
            @extend .px-3, .py-2;

            width: 16.5%;

            &:first-child {
                text-align: right;
            }
        }
    }

    &_data-list {
        @extend .list-unstyled;

        background-color: #fff;
        border: 1px solid #141C24;
        border-radius: 1rem;

        & > li {
            @extend .d-flex, .justify-content-between;

            border-bottom: 1px solid #141C24;

            &:last-child {
                border-bottom: 0;
            }

            &.display {
                .insights-by-time_data-list_cell:first-child {
                    background-color: #FF9900;
                    border-radius: 1rem 0 0 0;
                }
            }

            &.search {
                .insights-by-time_data-list_cell:first-child {
                    background-color: #F6DA82;
                }
            }
        }

        &_cell {
            @extend .px-3, .py-2;

            width: 16.5%;

            &:first-child {
                border-right: 1px solid #141C24;
                text-align: right;
            }
        }
    }
}

.insights-and-recommend {
    @extend .d-flex, .align-items-center, .justify-content-between;

    padding: 2rem;

    &_chart {
        width: 20rem;
    }

    &_data {
        width: calc(100% - 22rem);
    }
}

.paid-google-header {
    @extend .d-flex;

    align-items: center;
    margin-bottom: 32px;
}

.google-keywords-table {
  th, td {
    font-weight: normal;
  }
}

.title.m-10 {
  margin-top: 10px;
}


/* chart handler */
.paid-chart-handler {
  cursor: pointer;

  .eye-close {
    display: inline-block;
  }

  .eye-open {
    display: none;
  }

  &.active {
    .eye-close {
      display: none;
    }
  
    .eye-open {
      display: inline-block;
    }
  }
}

.paid-nav-links {
  @extend .d-flex, .justify-content-center;

  &_item {
    @extend .btn;

    color: #141C24;
    margin: 0 1rem;
    padding: 0.2rem 1rem;

    .icon {
      height: 0.75rem;
      width: 0.75rem;
    }

    &.conversions {
      background-color: #FFA8B0;
      border-color: #FFA8B0;
      &:hover {
        background-color: #fff;
        color: #FFA8B0;
      }
    }
  
    &.meta {
      background-color: #00A9DF;
      border-color: #00A9DF;

      &:hover {
        background-color: #fff;
        color: #00A9DF;
      }
    }
  
    &.google{
      background-color: #ff9933;
      border-color: #ff9933;
      
      &:hover {
        background-color: #fff;
        color: #ff9933;
      }
    }

    &.google-keywords {
      border-color: #ff9933;

      .icon {
        margin-left: 8px;
      }

      &:hover {
        background-color: #fff;
        color: #ff9933;

        .icon {
          stroke: #ff9933;
        }
      }
    }
  }
}


.conversions {
    .cards {
        .card {
            flex-grow: inherit;
            width: 200px;
        }
    }
}
