.internal-property {
  &_sub-block {
    &.profile-summary-section {
      .page-header {
        @extend .d-flex, .align-items-center;

        .icon {
          stroke: #91918A;
          height: 1rem;
          width: 1rem;
        }
      }

      .property-information_address {
        a {
          color: #141C24;
        }
      }

      .property-status-and-information {
        @extend .d-flex, .align-items-center;

        &_item {
          margin-right: 1rem;
        }
      }
    }
  }

  &_entry-action {
    text-align: right;

    .icon {
      height: 1em;
      stroke: #91918A;
      width: 1em;
    }
  }
}

.cards.property-profile {
  margin-bottom: 35px;
  padding-top: 25px;
}

.cards {
  .card {
    &.property-information, &.purchased-service {
      width: 250px!important;
    }

    &.property-detail {
      &_card {
        width: calc(100% - 30px);

        &:first-child {
          margin-bottom: 15px;
        }

        td {
          vertical-align: top;
        }
      }
    }
  }

  .property-detail, .enhancement-history, .purchased-service {
    width: calc((100% - 500px) / 2)!important;
  }

  .property-detail_card_value {
    display: block;
    font-size: 13px;
    margin: 5px 0;
  }
}

.text-gray {
  color: #666;
}

#alacarte-packages {
  position: relative;

  .alacarte-packages-content {
    width: calc(100% - 30px);
    overflow: hidden;
    
    .card {
      min-width: 200px;
      max-width: 200px;
    }
  }

  .alacarte-packages-scroll-handler {
    background-image: linear-gradient(#7ecaea, #0576b2);
    border-radius: 50%;
    display: inline-block;
    height: 36px;
    position: absolute;
    top: calc(50% - 30px);
    right: -8px;
    cursor: pointer;
    width: 36px;

    &::after {
      content: url("~arrow-white.png");
      left: 12px;
      position: absolute;
      transition: all 0.35s ease;
      top: 9px;
    }

    &.prev {
      &::after {
        left: 9px;
        top: 5px;
        transform: rotate(180deg);
      }
    }
  }
}

.gray-border {
  border-top: 2px solid #eee;
  td {
    padding-top: 5px;
  }
}

.properties_show {
  .weather-updates, .social-info {
    height: 43px;
  }

  .scrollable-card section {
    height: 294px;
  }
  
  .property-detail_card_content {
    max-height: 600px;
    padding-right: 12px;
    overflow: auto;
  }
}

.internal-property .section-title.weather {
  color: #535657;
}

.weather-updates {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &_perticle {
    display: flex;
    justify-content: space-between;
    width: calc(50% - 8px);

    .date-text {
      color: #91918A;
      display: block;
      margin-bottom: 5px;
    }

    .weather-updates_temparature {
      font-size: 16px;
      font-weight: 500;
    }

    img {
      height: 40px;
      background-color: #ccc;
      float: right;
    }

    .image-placeholder {
      display: inline-block;
      height: 40px;
      width: 40px;
      background-color: #ccc;
      float: right;
    }
  }
}

.local-photo-management-table .delete-info-entry .icon {
  width: 20px;
}

/* edit info entry form */
.edit_property_info_entry, .new_property_info_entry {
  h6, label { font-size: 12px !important; }
  label { 
    color: #888 !important;
    font-weight: 400 !important;
  }
  
  .request-form_block .tox-tinymce {
    height: 200px !important;
  }

  .tox-menubar, .tox-statusbar__branding { display: none !important; }

  .form-control.bootstrap-select .btn.dropdown-toggle.btn-light {
    border: 1px solid #ccc !important;
    border-radius: 10px;
    margin-bottom: 10px;
    padding-top: 6px;
  }

  .filter-option-inner-inner {
    font-weight: 400 !important;
    font-size: 12px !important;
  }
}

.local-photo-management-table table{
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #f2efea;
  td {
    padding: 10px 12px;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #EDE6DC;
}

.asset-auditing_list {
  border-radius: 10px;
  li:last-child {
    border-radius: 0 0 10px 10px;
  }
}

.branding_list_item_title {
  margin: 5px 0;
  display: block;
}

.internal-property {
  @extend .d-flex, .justify-content-between;

  .section-title {
    color: #535657;
  }

  li.avoid_points {
    background-color: rgba(225, 59, 86, 0.12);
    padding: 1rem;
    border-bottom: 2px solid #E13B56;
    margin-bottom: 1rem;
  }

  li.updates {
    background-color: #F6F3EE;
    border-bottom: 2px solid #91918A;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .service-enhancements {
    span {
      display: inline-block;
      margin-right: 10px;
      color: #888;
      vertical-align: top;
      text-align: center;
    }
  }
  .card {
    margin: 0;
    .bottom-center {
      text-align: right !important;
      background-color: #fff;
      padding: 0.5rem 2rem 0 0;
    }
  }
  
  .property-detail-card_header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .section-title {
      margin-bottom: 0;
    }

    margin-bottom: 8px;
  }

  #internal-updates, #service-details {
    margin-top: 16px;
    width: 240px;

    &.card {
      padding: 18px 6px 18px 18px;
    }
  }

  #internal-updates {
    .updated-info {
      text-align: left;
    }
  }

  &_primary-block {
    margin-top: 13px;
    width: calc(100% - 264px);
  }

  &_sub-block, &_secondary-block {
    margin-top: 13px;
    width: 240px; 
  }

  &_secondary-block.notes {
    .asset_notes-card {
      height: 443px;
    }

    #internal-asset_notes {
      .property-detail-card_container {
        min-height: 406px;
      }
    }
  }

  &_sub-block {
    .page-header {
      span {
        margin: 7px 0 0 5px;
      }
    }
  }

  #internal-local_photo_management {
    .section-title {
      margin-bottom: 10px;
    }
  }

  .card, .cards .card.property-detail_card:first-child {
    margin-bottom: 16px;
  }

  .cards.property-cards .card {
    margin: 50px 0 0;
    width: 100%;

    &.asset-auditing {
      width: 100%;
    }
  }

  .property-detail {
    &_card {
      &_header {
        @extend .d-flex, .justify-content-between;
      }

      .internal-property_updates {
        margin-bottom: 0;

        &_item {
          margin-bottom: 0;
        }
      }
    }

    &_card-wrapper {
      padding-left: 5px;
      width: calc(100% - 420px);
  
      .points-to-avoid {
        background-color: #CC2A3C;
  
        .section-title {
          a {
            color: #9a9a9a;
  
            &.add, &.edit {
              color: #9a9a9a;
            }
          }
        }
  
        &_list {
          color: #fff;
          height: 210px;
  
          &_item {
            border-bottom: 2px solid #E13B56;
            margin-bottom: 15px;
            padding-bottom: 10px;
  
            .updated-info {
              color: #fff;
            }
          }
        }
  
        .updated-info {
          color: #fff;
        }
      }
    }
  }

  #service-details {
    min-height: 302px;
  }

  #internal-property_details, #internal-neighborhood_details {
    height: 353px;

    .property-detail-card_container {
      margin-bottom: 0;
      height: 327px;
    }
  }

  #internal-branding-notes {
    height: 289px;
  }

  /*#internal-property_details, #internal-neighborhood_details, #internal-asset_notes, #internal-local_photo_notes*/ 
.property-detail-card {
  width: 240px;

  &_header {
    @extend .d-flex, .justify-content-between;
  }

  &_container {
    background-color: #fff;
    border-radius: 12px;
    padding: 24px 12px 12px 12px;
    margin-bottom: 16px;
    min-height: 287px;
  }

  &_container_content {
    height: 604px;

    .internal-property_updates {
      margin-bottom: 0;

      &_item {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.scrollable-card {
  padding-right: 12px;
}

#internal-branding {
  .branding_list {
    height: 248px;
    overflow: auto;
  }
}

#internal-property_details, #internal-neighborhood_details {
  .scrollable-card {
    height: 275px;
    overflow: auto;
  }
}

.local-photo-management-table {
  .photo-manage {
    height: auto;
    max-height: 347px;
    overflow: auto;
  }

  .location-name {
    width: 200px;
  }

  .instagram {
    width: 200px;
  }

  .distance {
    width: 70px;
  }

  .photoshoot {
    width: 150px;
    a {
      color: #00558D;
    }
    .icon {
      stroke: #00558D;
    }
  }

  .added-by {
    width: 200px;
  }

  .delete {
    width: 36px;
  }
}

#internal-asset_notes {
  .scrollable-card {
    height: 358px;
    overflow: auto;
  }
}

#internal-local_photo_notes {
  .scrollable-card {
    height: 370px;
    overflow: auto;
  }
}

.asset-auditing {
  width: 100%;
  .section-title {
    margin-bottom: 10px;
  }

  &_list-header {
    align-items: center;
    display: flex;
    padding: 18px 24px 18px 48px;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
  }

  &_list {
    @extend .list-unstyled;

    background-color: #fff;
    max-height: 412px;
    overflow: auto;

    &_item {
      padding: 18px 24px 18px 48px;

      &:nth-child(odd) {
        background-color: #EDE6DC;
      }

      &_info, {
        display: flex;

        .icon {
          stroke: #91918A;
          height: 12px;
          width: 12px;
        }
      }

      &_info-details {
        &_list {
          margin-bottom: 15px;
          list-style: disc;
          padding-left: 15px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  &_item_status-wrapper {
    width: 160px;
  }

  .asset-auditing_item_type {
    width: 100px;
  }

  .asset-auditing_item_status {
    width: 160px;
    text-align: right;
    margin-right: 32px;
  }

  .asset-auditing_item_time {
    width: 380px;
  }
}

  .social-info {
    height: 72px;
    margin-bottom: 16px;
  }

  .social-info {
    &_header, &_list_item {
      @extend .d-flex, .justify-content-between;

      span {
        color: #91918A;
        width: calc(33.33% - 0.5rem);
      }
    }

    &_list {
      @extend .list-unstyled;

      &_item {
        span {
          color: #141C24;
        }
      }
    }
  }

  .vibe {
    width: calc(100% - 310px);
  }

  .vibe {
    &_list {
      height: 168px;
    }
  }

  &_updates {
    &_item {
 
       padding-bottom: 10px;
    }
  }

  .service-details {
    &_header {
      @extend .d-flex, .justify-content-between;
    }

    &_info {
      background-color: #fff;
      border-radius: 12px;
      padding: 24px;
    }

    .internal-property_social-links {
      @extend .list-unstyled, .d-flex, .justify-content-between;
  
      margin-bottom: 0;
      width: 160px;
    }
  }

  .service-enhancements {
    margin-top: 16px;
  }

  .campaign-history {
    .enhancement-history_campaigns_list {
      &_item {
        &:hover {
          background-color: #8CD4E6;
        }
      }
    }
  }

  .management-marketing {
    .section-title {
      margin-bottom: 4px;
    }

    &_block {
      margin-bottom: 10px;
    }
  }

  .updated-info {
    color: #91918A;
    font-size: 10px;
    margin-bottom: 0;
    text-align: right;
  }

  .internal-profile-info-banner {
    background-repeat:no-repeat;
    background-size:cover;
    border-radius: 11px;
    height:200px;
    margin: -17px -20px 30px;
    position: relative;
  }

  .profile-pic-container {
    align-items: center;
    background-color: #fff;
    bottom: -25px;
    border: 3px solid #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: calc(50% - 50px);
    height: 100px;
    width: 100px;

    .profile-pic-bg {
      max-width: 100%;
      height: 100%;
    }

    .profile-pic {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .asset-auditing_list_item {
    .short-details {
      display: block;
    }
    .asset-auditing_list_item_info-details {
      display: none;
    }

    .icon.arrow {
      height: 16px;
      width: 16px;
      background-position: -170px -505px;
      transform: rotate(-90deg);
    }

    &.detail-visible {
      .asset-auditing_list_item_info-details {
        display: block;
      }
      .short-details {
        display: none;
      }
  
      .icon.arrow {
        height: 16px;
        width: 16px;
        background-position: -170px -505px;
        transform: rotate(0deg);
      }
    }

    .asset-auditing_item_time {
      .icon {
        transform: rotate(-90deg);
        stroke: #91918A;
        height: 1em;
        width: 1em;
        transition: all 0.35s ease;
      }
    }

    &.detail-visible {
      .asset-auditing_item_time {
        .icon {
          transform: rotate(0deg);
        }
      }
    }
  }

  .asset-auditing_item_status {
    &_chip {
      border-radius: 13px;
      color: inherit;
      display: inline-block;
      font-weight: normal;
      padding: 3px 15px 2px;
      border: 1px solid #00558D;

      &.renders-only {
        border-color: #E13B56; 
      }
  
      &.render-photo-mix {
        border-color: #ff9933;
      }
  
      &.limited-assets {
        border-color: #00558D;
      }
  
      &.complete-assets {
        border-color: #007BFF;
      }
  
    }
  }
}

/**Edit Property**/

.edit_property {
  max-height: calc(100vh - 200px);
  padding: 0 12px;
  overflow: auto;

  .edit-property_label {
    align-items: center;
    display: flex;

    .social-link-icon-wrapper {
      margin-right: 18px;
    }
  }

  input.form-control {
    background-color: transparent;
    border: 1px solid #ccc;
  }
}

.custom-file-filed {
  &_wrapper {
    height: 64px;
    position: relative;
  }

  &_icon {
    background-color: #eee;
    background-position: -192px -668px;
    border-radius: 5px;
    height: 64px;
    left: 0;
    position: absolute;
    top: 0;
    width: 84px;
  }

  #property_logo_attributes_file, #property_banner_attributes_file {
    height: 64px;
    opacity: 0;
  }
}

.edit-info-entry{
  color: #141c24;
  padding-left: 10px;
}

.add-info-entry {
  color: #91918A;
}

.points-to-avoid .edit-info-entry {
  color: #fff;
}

.bottom-center {
  width: 100%;
}

#internal-local_photo_notes p {
  a, .updated-info {
    font-weight: normal;
  }
}

.vibe, .branding {
  &_list {
    &_item {
      &_title {
        color: #969696;
      }
    }
  }
}

.type {
  &_status-style {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    &_title {
      color: #969696;
    }
  }
}

.type-style, .type-status {
  border-radius: 13px;
  font-weight: 600;
  padding: 3px 15px 2px;
}

.type-style {
  &.student-housing {
    background-color: #FF9900;
  }

  &.townhomes {
    background-color: #CC2A3C;
  }

  &.high-rise {
    background-color: #00A9DF;
  }

  &.mid-rise {
    background-color: #00558D;
  }

  &.garden-style {
    background-color: #F86A8D;
  }

  &.single-family-rental{
    background-color: black;
  }
}

.type-status {
  &.construction-not-open {
    background-color: #CC2A3C;
  }

  &.partial-open-lease-up {
    background-color: #FF9900;
  }

  &.open-lease-up {
    background-color: #00558D;
  }

  &.stable {
    background-color: #00A9DF;
  }
}

.custom-file-filed_wrapper .file-name {
  position: absolute;
  top: 48px;
  left: 100px;
}

#internal-highlights, #internal-branding, #internal-branding-notes {
  height: 290px;

  &.card {
    padding: 24px 12px 24px 24px;

    .nicescroll {
      padding-right: 12px;
    }
  }

  #highlights-container {
    height: 220px;
  }

  #branding_list {
    height: 220px;
  }
}

.delete-confirmation-modal {
  .modal-header {
    display: none;
  }
}

.delete-info-entry {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 0.3rem;
}

.property-information {
  &.card {
    flex-grow: unset;
    width: 400px;
  }

  &_address {
    span {
      display: block;
      color: #535657;
      margin: 10px 0 2px 0;
    }

    .property-date {
      color: $primaryBtn;
      margin: 10px 0;
    }

    .icon-dot {
      display: inline-block;
      margin-right: 16px;
    }
  }

  &_status {
    border-radius: 15px;
    color: #fff;
    display: inline-block;
    margin: 20px 0 20px 0;
    padding: 3px 15px;

    &.red{
      background-color: #ef91a1;
    }

    &.blue{
      background-color: #00adee;
    }

    &:hover {
      color: #fff;
    }
  }

  .card_title {
    margin: 15px 0 2px;
  }
}

.property-detail {
  &_category {
    color: #747373;
    font-style: italic;
  }

  .card_title {
    text-transform: uppercase;
    font-size: 24px;
  }

  &_summary {
    margin: 20px auto 0;
    max-width: 300px;
    text-align: left;

    &_item {
      margin-bottom: 15px;
      width: 48%;
    }

    &_label {
      color: #747373;
    }

    &_value {
      font-size: 16px;
      font-weight: 600;

      &_unit {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  &_source {
    font-weight: 500;
    margin-bottom: 0;
  }
}

.local-photo-management-table {
  margin-bottom: 0;
}

.purchased-service {
  &_list {
    &_title {
      font-size: 14px;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    &_date {
      color: #666;
    }
  }
}

.asset-auditing_list_item {
  p {
    margin: 0;
    padding: 0;
  }

  .icon.bottom {
    display: none;
  }

  &.detail-visible {
    .icon.right {
      display: none;
    }
    .icon.bottom {
      display: block;
    }
  }
}

.property-detail-card_container p {
  margin: 0;
  padding: 0;
}

.internal-property.asset-and-local-photo-managemnet {
  margin-top: 10px;
}
