.analytics-wrapper {
    .analytics-chart {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;

        .analytics-content_tabs {
            width: 680px;
        }

        .analytics-total-traffic {
            margin: 0;
            flex-grow: unset;
            width: 328px;
        }
    }

  .table-view_row {
    padding: 1rem;
    span.index {
      display: inline-block;
      width: 30px;
    }

    &.total {
      background-color: #17568D;
      color: #fff;
    }
  }
}

.analytics-content_tabs {
    .nav-tabs .nav-item {
        width: calc(25% - 4px);
    }

   .nav-item {
        &.users {
            border-color: #00A9DF;
        }

        &.new_users {
            border-color: #8CD4E6;
        }

        &.conversions {
            border-color: #F6DA82;
        }

        &.sessions {
            border-color: #FF9900;
        }
    }

    .active-tab-users {
        .nav-item.users {
            background-color: #fff;
            margin-top: 0;
            padding-top: 0.5rem;

            &::after {
                background-color: #fff;
            }
        }

        .tab-content-wrapper {
            border-color: #00A9DF;
            /*border-radius: 0 1rem 1rem 1rem;*/
        }
    }

    .active-tab-new_users {
        .nav-item.new_users {
            background-color: #fff;
            margin-top: 0;
            padding-top: 0.5rem;

            &::after {
                background-color: #fff;
            }
        }

        .tab-content-wrapper {
            border-color: #8CD4E6;
        }
    }

    .active-tab-conversions {
        .nav-item.conversions {
            background-color: #fff;
            margin-top: 0;
            padding-top: 0.5rem;

            &::after {
                background-color: #fff;
            }
        }

        .tab-content-wrapper {
            border-color: #F6DA82;
        }
    }

    .active-tab-sessions {
        .nav-item.sessions {
            background-color: #fff;
            margin-top: 0;
            padding-top: 0.5rem;

            &::after {
                background-color: #fff;
            }
        }

        .tab-content-wrapper {
            border-color: #FF9900;
        }
    }
}

.analytics-total-traffic {
    background-color: #fff;
    padding: 1rem;

    &_title {
      font-size: 1rem;
    }
}

.source-medium-table {
  .table-view_cell {
    width: 15%;

    &.source-medium {
      width: 40%;
    }
  }
}

.barchart {
  height: 360px;
  position: relative;
  display: flex;
  justify-content: space-between;
  bottom: -20px;

  .bar {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: calc(25% - 0.5rem);
    
        &_height {
            border-radius: 1rem 1rem 0 0;
        }

        &_key {
            margin-top: 5px;
        }

        &_key {
            height: 1.5rem;
            &.google-ads {
              width: 64px;
            }
        }
    }
}

#analytics-table {
  height: 490px;
  min-height: 490px;
}
