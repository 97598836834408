span.user-avatar {
  background: #8CD4E6;
  padding: 16px;
  font-size: 16px;
  color: #fff;
  border-radius: 50%;
}

.user-setting {
  width: 504px;
  margin: 0 auto;

  .page-title {
    font-size: 32px;
  }

  span.user-avatar {
    background: #8CD4E6;
    padding: 50px 40px;
    font-size: 30px;
    display: inline-block;
    width: auto !important;
  }

  .profile-photo-upload-section {
    @extend .d-flex;

    .profile-photo {
      margin-right: 36px;
      width: 152px;
    }

    .profile-photo-upload {
      width: 241px;
      
      p {
        color: #535657;
      }
    }
  }
  
  .dropzone {
    border: 1px dashed #ccc !important;
    color: #bbb !important;
    min-height: auto;
  }

  .card {
    border: 1px solid #D0CBBD;
    border-radius: 8px;
    padding: 24px;
    margin: 52px 0;
    width: 100%;

    .chnage-password {
      width: 368px;
    }

    h4 {
      text-align: left;
      font-size: 12px;
      margin-bottom: 20px;
    }

    .form-group {
      margin-bottom: 20px;
    }

    .label {
      color: #91918A;
    }

    .form-control {
      border-radius: 8px;
    }

    .btn-primary {
      background-color: #00558D;
      border-color: #00558D;
    }

    #edit-password-form {
      label {
        display: block;
        text-align: left;
      }
    }
  }

  .properties-enabled-section {
    border: 1px solid #D0CBBD;
    border-radius: 8px;
    margin-top: 28px;

    &_header {
      border-bottom: 1px solid #D0CBBD;

      &.separator {
        margin: 0;
        padding: 17px 24px 11px 24px;
      }

      p {
        margin-bottom: 0;
      }
    }

    .property-checkboxes {
      padding: 24px;
    }
  }

  .form-check {
    margin: 10px 0;
    input {
      margin-top: 0.1rem;
    }
  }

  .property-checkboxes {
    height: 300px;
    overflow: auto;
  }

  .profile-picture {
    .profile-photo {

      .profile-photo {
        &_container {
          align-items: center;
          background-color: #efefee;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          height: 152px;
          overflow: hidden;

          .user-avatar {
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }

    .dropzone .dz-button a {
      color: #00558D !important;
    }
  }

  .separator {
    margin: 20px 0 10px 0;
    border-bottom: 1px solid #D0CBBD;
    padding-bottom: 10px;
  }

  .form-control {
    background: #fff;
    border: 1px solid #D0CBBD;
    &:disabled {
      background-color: #D0CBBD;
    }
  }
}
