.footer {
  color: #00adef;

  .partners {
    float: right;

    .icon {
      display: inline-block;
      height: 3rem;
      width:3rem;

      &.icon-sk-footer {
        background-position: -4.2rem -10rem;
      }

      &.icon-fb-footer {
        background-position: -4.1rem -13.9rem;
      }

      &.icon-insg-footer {
        background-position: -4.2rem -17.7rem;
      }
    }
  }
}
