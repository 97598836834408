#sk-left-panel_property-select {
  background-color: transparent;
  color: #fff;
  width: 100%;
  padding: 1rem 0;
  border: none;

  option {
    color: #8eaac8;
    background-color: transparent;
  }
}

.select-device {
  position: absolute;
  right: calc(0% + 266px);
  top: 22px;

  select {
    background: transparent;
  }
}

a.download-leads {
  float:right;
  margin-top: 5px;
  &:hover {
    text-decoration: none;
  }
}

.top-google-ads {
  th.cost-per-click {
    min-width: 123px;
  }

  th.total-ad-spend {
    min-width: 128px;
  }
}

#start-date {
  color: transparent;
}