.resources-search-now {
  padding: 15px 5px;

  .form-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .form-control {
    margin-right: 15px;
    width: calc(100% - 125px);
  }
}

.links-section {
  margin-bottom: 25px;
}

.faq-section {
  font-size: 14px;
  text-transform: uppercase;

  a {
    display: inline-block;
    color: #333;
    font-weight: 500;
    margin: 5px 0;
  }

  small {
    text-transform: none;
    color: #666;
    display: block;
    margin: 0 0 10px 0;
  }
}

.contact-now {
  &_title {
    font-size: 16px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  .btn {
    margin: 0 10px;
  }
}

.search-result {
  font-size: 14px;
  text-transform: capitalize;

  .accordion_group .accordion_title {
    display: block;
    margin-top: 15px;
  }

  &_list{
    height: calc(100vh - 395px);
    line-height: 26px;
    margin-bottom: 0;
    overflow: auto;
    text-transform: none;

    &_item {
      margin: 15px 0;
    }
  }

  &_summary, &_title {
    text-transform: uppercase;
  }

  &_title {
    color: #666;
  }
}
