// https://codepen.io/shankarcabus/pen/GzAfb

@mixin circle($size) {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - #{$size/2});
  top: calc(50% - #{$size/2});
  width: $size;
  height: $size;
}

$size: 140px;
$bigger-size: $size + 1;

.progress-pie-chart {
  width: $size;
  height: $size;
  border-radius: 50%;
  border: 2px dotted #E5E5E5;
  position: relative;
  &.gt-50 {
    border: 4px solid #00adee;
  }
}
.ppc-progress {
  @include circle($size);
  clip: rect(0, $size, $size, #{$size/2});
  .ppc-progress-fill {
    @include circle($size);
    clip: rect(0, #{$size/2}, $size, 0);
    border: 4px solid #00adee;
    transform: rotate(60deg);
  }
  .gt-50 & {
    clip: rect(0, #{$size/2}, $size, 0);
    .ppc-progress-fill {
      content: "";
      position: absolute;
      border-radius: 50%;
      left: calc(50% - #{$bigger-size/2});
      top: calc(50% - #{$bigger-size/2});
      width: #{$bigger-size};
      height: #{$bigger-size};
      clip: rect(0, $bigger-size, $bigger-size, #{$bigger-size/2});
      border: 2px dotted #E5E5E5;
      background: #fff;
    }
  }
}

.ppc-percents {
  @include circle(#{$size/1.15});
  background: #fff;
  text-align: center;
  display: table;
  span {
    display: block;
    font-size: 2.6em;
    font-weight: 500;
    color: inherit;
  }
}

.pcc-percents-wrapper {
  display: table-cell;
  vertical-align: middle;
}
