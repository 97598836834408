.main-container {
  @extend .d-flex;

  .main-nav {
    @extend .list-unstyled;
  }

  &_primary-panel-wrapper {
    background-color: #f6f3ee;
    border-radius: 0 0 0 1rem;
    overflow: auto;
    width: 100%;
  }

  &_right-panel {
    height: calc(100vh - 72px);
    overflow: auto;
  }

  &_primary-panel {
    margin-left: 24px;
    padding: 36px 0 113px;
  }

  &_left-panel {
    @extend .primary-bg, .list-unstyled;

    min-width: 72px;
    padding: 70px 0 0 8px;

    .main-nav > li {
      border-radius: 20px 0 0 20px;
      margin-bottom: 20px;
      padding: 12px 0 12px 18px;

      span {
        display: none;
      }
  
      a {
        align-items: center;
        color: #fff;
        display: flex;
        text-decoration: none;
        text-transform: capitalize;

        .icon {
          stroke: #f6f3ee;
          margin-right: 18px;
          height: 14px;
          width: 14px;
        }
      }
  
      &:hover, &.active {
        background-color: #f6f3ee;
        box-shadow: 0px 4px 26px 0px rgba(20, 28, 36, 0.20);
        
        a {
          color: #141C24;
        }

        .icon {
          stroke: #141c24;

          &.home {
            background-position: 3px 0px;
          }
        
          &.social {
            background-position: 4px -104px;
          }
        
          &.paid {
            background-position: 4px -207px;
          }
        
          &.analytics {
            background-position: 4px -301px;
          }
        
          &.requests {
            background-position: 4px -391px;
          }
        
          &.reports {
            background-position: 4px -496px;
          }
        
          &.profile {
            background-position: 4px -601px;
          }
        }
      }
    }
  }
}

.primary-bg {
  background-color: $blue;
}

.dashboard-title-header {
  @extend .d-flex, .align-items-center, .justify-content-between;

  margin-bottom: 18px;

  .page-title {
    margin-bottom: 0;
  }
}

.page-header-with-title-and-download {
  .page-title  {
    margin-bottom: 18px;
  }
}

.download-link {
  margin-bottom: 13px;
  text-align: right;

  &_item {
    color: #91918A;
    font-size: 12px;
    font-weight: 400;
    margin-left: 16px;
    text-transform: uppercase;
  }
}

.growth {
  font-size: 12px;
  svg {
    width: 12px;
  }
}

.greetings {
  color: #535657;
  font-family: "alkaline",sans-serif; 
  font-size: 1.25rem;
  margin-bottom: 0;
}

.page-title {
  color: #141c24;
  font-size: 24px;
  letter-spacing: normal;
  margin-bottom: 24px;
  text-transform: capitalize;
}

.table-view {
  border-radius: 0 0 12px 12px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);

  &_header {
    align-items: center;
    background-color: #fff;
    border-radius: 12px 12px 0 0;
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 1rem;

    .table-view_cell {
      a {
        color: #141C24;
      }

      &.active, &:hover {
        color: #00558D;

        a {
          color: #00558D;
        }
      }
    }
  }

  &_row-container {
    background-color: #fff;
    height: calc(100vh - 23em);
    list-style: none;
    margin-bottom: 0;
    overflow: auto;
    padding: 0;
  }

  &_row {
    align-items: center;
    border-color: #f6f3ee;
    border-style: solid;
    border-width: 1px 0;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #f6f3ee;

    &:nth-child(even){
      background-color: #EDE6DC;
    }

    &.paused{
      background-color: #D0CBBD !important;
    }
    
    &.onboard {
      background-color: #8CD4E6 !important;
    }
    
    &.former{
      background-color: #FFA8B0 !important;
    }
  }

  &_cell {
    @extend .d-flex, .justify-content-start, .align-items-center;

    & > a {
      max-width: 100%;
      overflow: hidden;
      padding-right: 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.name {
      width: 9rem;

      a, i {
        vertical-align: middle;
      }

      a {
        display: inline-block;
      }
    }

    &.start-date {
      width: 6rem;
    }

    &.social-media {
      width: 5rem;
    }

    &.meta-ads, &.goodle-ads, &.gbp, &.stories {
      text-align: center;
      margin: 0;
    }

    &.meta-ads {
      width: 6rem;
    }

    &.goodle-ads {
      width: 7rem;
    }

    &.gbp {
      width: 4rem;
    }

    &.stories {
      width: 5rem;
    }

    &.counts {
      position: relative;
      width: 6.3rem;

      .icon {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }

      .info {
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 1rem;
        display: none;
        position: absolute;
        top: 1.5rem;
        right: 0;
        z-index: 99;
        width: 250px;

        span {
          display: block;
          padding: 5px 0;
        }
      }

      &:hover {
        .info {
          display: inline-block;
        }
      }
    }

    &_dot {
      position: relative;

      .alert-name {
        background-color: #fff;
        border-radius: 3px;
        display: none;
        left: 0;
        margin-bottom: 0;
        padding: 2px 8px;
        position: absolute;
        white-space: nowrap;
      }

      &:hover {
        .alert-name {
          display: inline-block;
        }
      }
    }

    .counts_number {
      background-color: #E13B56 !important;
      border-radius: 50%;
      color: #fff;
      display: inline-block;
      height: 1.5rem;
      margin-right: 0.5rem;
      padding-top: 0.3rem;
      text-align: center;
      width: 1.5rem;
    }
  }
}


.properties_index {
  .site-header_left-section .select-property .bootstrap-select {
    background-color: #F6F3EE;
  }

  .site-header_left-section .select-property .dropdown-toggle {
    padding: 6px 8px 0 8px;
  }

  .site-header_left-section .select-property {
    height: 36px;
    position: relative;
  }

  .bootstrap-select .photo-thumbnail_container, .select-property .photo-thumbnail_container {
    height: 24px;
    width: 24px;
  }

  .search-and-notification .nav-search {
    top: 10px;
    cursor: pointer;
  }

  .select-property {
    background-color: #F6F3EE;
    border-radius: 6px;

    .btn.dropdown-toggle.btn-light {
      background-color: transparent;
    }
  }
  .filter-option-inner-inner {
    display: none !important;
  }

  .search-and-notification .nav-search {
    left: 17px;
  }

  .tooltip .arrow {
    display: none;
  }
  .tooltip-inner {
    font-size: 12px;
    border: 1px solid #f6f3ee;
    text-align: left;
  }
  .tooltip.onboard .tooltip-inner  {
    background-color: #8CD4E6;
    color: #333;
  }
  
  .tooltip.former .tooltip-inner  {
    background-color: #FFA8B0;
    color: #333;
  }
  
  .tooltip.paused .tooltip-inner  {
    background-color: #D0CBBD;
    color: #333;
  }

  .tooltip.instagram .tooltip-inner  {
    background-color: #E13B56;
    color: #fff;
  }

  .tooltip.google .tooltip-inner  {
    background-color: #FF9900;
    color: #fff;
  }

  .tooltip.pixel .tooltip-inner  {
    background-color: #00558D;
    color: #fff;
  }

  .tooltip.facebook .tooltip-inner  {
    background-color: #00A9DF;
    color: #fff;
  }

  .tooltip.other .tooltip-inner  {
    background-color: #141C24;
    color: #fff;
  }
}

 .nav-search {
  cursor: pointer;
 }
