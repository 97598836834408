.report-form {
  select {
    width: 100%;
  }
}

.top-cards {
  border-radius: 0.5rem;
  display: flex;
  outline: 1px solid #141C24;
  padding: 10px 5px;

  .title {
    font-size: 1rem;
    font-weight: bold;
  }

  &_name {
    margin: 0 auto;
    padding: 10px 0px;
  }

  &_buttons {
    display: flex;
    gap: 10;

    a {
      display: inline-block;
      border: 1px solid blue;
      border-radius: 0.5rem;
      padding: 5px;
      margin-right: 2pauthox;
    }
  }
}

.error-msg-container {
  color: red;
  margin: 10px 0;
}

.custom-report-help {
  border-top: 1px solid #ddd;
  text-align: left;
  color: #888;
  padding: 40px;
  margin-top: 20px;
  text-align: left;
  font-size: 14px;

  section {
    width: 510px;
    margin: 0 auto;
  }

  &_title {
    font-weight: 500;
    color: #141C24;
    margin-bottom: 30px;
  }

  ul {
    margin-bottom: 30px;
    li {
      color: #333;
      font-size: 14px;
      margin: 10px 0;
    }
  }

  &_content {
    color: #333;
    line-height: 25px;
  }
}

.select-report-filter {
  @extend .d-flex, .justify-content-between, .align-items-center;

  &_item {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    max-width: 240px;
    width: 240px;
    padding: 15px;
    position: relative;

    .icon {
      position: absolute;
      top: 20px;
      right: 10px;
      z-index: 1;
    }

    .icon.bottom {
      display: none;
    }
    .icon.right {
      display: block;
    }

    &.show {
      .icon.bottom {
        display: block;
      }
      .icon.right {
        display: none;
      }
    }

    span {
      font-weight: 500;
    }

    .btn {
      padding: 0;
      .filter-option-inner-inner {
        font-size: 12px;
        font-weight: 400;
        color: #333;
      }
    }
    
    .bootstrap-select {
      height: 20px;
      &.show-tick {
        .icon.bottom {
          display: none;
        }

        .icon.right {
          display: block;
        }
      }
    }

    .dropdown-menu {
      min-width: 238px;
      left: -15px !important;
      span.text {
        font-size: 12px;
      }
    }

    &.properties-filter {
      border: 1px solid #00558D;

      .dropdown.bootstrap-select > .dropdown-menu {
        border: 1px solid #00558D;
      }
    }

    &.social-filter {
      border: 1px solid #00A9DF;

      .dropdown.bootstrap-select > .dropdown-menu {
        border: 1px solid #00A9DF;
      }
    }

    &.paid-filter {
      border: 1px solid #FF9900;

      .dropdown.bootstrap-select > .dropdown-menu {
        border: 1px solid #FF9900;
      }
    }

    &.additional-metrics-filter {
      border: 1px solid #F86A8D;

      .dropdown.bootstrap-select > .dropdown-menu {
        border: 1px solid #F86A8D;
      }
    }

    .btn.dropdown-toggle.btn-light.bs-placeholder {
      background-color: #fff;
      border-radius: 6px;

      .filter-option-inner {
        margin-top: 5px;
      }

      &::after {
        border-color: #535657;
        border-style: solid;
        border-width: 2px 2px 0 0 !important;
        border: 2px solid #141C24;
        border-radius: 2px;
        height: 12px;
        transform: rotate(45deg);
        width: 12px;
      }
    }
  }
}

.previous-reports {
  margin-top: 25px;

  &_table-wrapper {
    background-color: #fff;
    border-left: 16px solid #00558D;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem #dae3e6;

    td {
      .icon {
        &:hover {
          stroke: #E13B56;
        }
      }
    }
  }

  .properties {
    width: calc(100% - 688px);
  }

  .property-type {
    width: 170px;
  }

  .property-date-range {
    width: 190px;
  }

  .property-time {
    width: 166px;
  }

  .property-pdf {
    width: 162px;
  }

  .property-pdf-delete {
    width: 50px;
  }

  a.delete:hover {
    background-position: -104px -589px;
  }
}

.report-forms {
  &_card {
    width: calc(33.33% - 1rem);

    &_title {
      color: #00558D;
    }

    .schedule-option {
      display: none;
    }

    .standard-report-item {
      display: block;

      &_info {
        margin-bottom: 16px;
        min-height: 34px;

        p {
          margin-bottom: 0;
        }
      }
    }

    &.show-schedule-option {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);

      .schedule-option {
        display: block;
      }

      .standard-report-item {
        display: none;
      }
    }
  }
}

.report-forms {
  @extend .d-flex, .justify-content-between;

  .card {
    background-color: transparent;
    padding: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
  }

  &_item-container {
    width: 328px;
  }

  &_item {
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.5rem #dae3e6;
    min-height: 142px;
    padding: 1rem;
    width: 100%;

    .icon {
      height: 0.75rem;
      margin-left: 0.25rem;
      stroke: #535657;
      width: 0.75rem;
    }

    &.show-schedule-option {
      .schedule-options {
        display: flex;
        margin-bottom: 40px;
      }

      .file-types {
        margin-bottom: 48px;
      }

      .btn.btn-primary.kapture-blue {
        .icon {
          stroke: #fff;
        }
      }
    }
  }

  .btn {
    margin-right: 16px;
  }
}

.custom-report_card {
  .btn {
    margin-right: 16px;
  }
}

.custom-report {
  @extend .d-flex, .justify-content-between;

  margin-bottom: 40px;

  &_card-wrapper {
    width: calc(50% - 12px);
  }

  &_card {
    background-color: #fff;
    border-left: 16px solid #00A9DF;
    border-radius: 12px;
    min-height: 142px;
    padding: 1rem;

    .schedule-options {
      margin-bottom: 40px;
    }

    .file-types {
      margin-bottom: 48px;
    }

    .btn {
      .icon {
        height: 14px;
        margin-left: 12px;
        stroke: #535657;
        width: 14px;
      }

      &.btn-primary.kapture-blue {
        .icon {
          stroke: #fff;
        }
    }
    }
    
    &:first-child {
      border-left-color: #00558D;
    }
  }

  #report-form-card-full-portfolio {
    border-left: 16px solid #00A9DF;
  }
}

.custom-reports-preview {
  display: none;
  margin-top: 25px;

  .properties {
    width: 300px;
    max-width: 400px;
  }

  .property-engagement-rate {
    min-width: 150px;
  }

  .property-social-reach {
    min-width: 150px;
  }

  .property-social-impression {
    min-width: 170px;
  }

  .property-paid-clicks {
    min-width: 120px;
  }

  .property-paid-reach {
    min-width: 100px;
  }

  .property-paid-impression {
    min-width: 150px;
  }

  .property-tiktok {
    min-width: 150px;
  }

  .property-totals {
    min-width: 150px;
  }

  .property-comparison {
    min-width: 150px;
  }
}

.custom-reports-list {
  margin-top: 40px;

  &_table-wrapper {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 0.5rem #dae3e6;
    overflow: hidden;

    svg.icon {
      stroke: #91918A;
    }
  }
}

.report-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 44px;
  position: relative;

  .page-title {
    margin: 0 20px 0 0;
  }
  
  &_title-and-date {
    align-items: center;
    display: flex;

    .custom_report_name_input {
      background-color: transparent;
      width: auto;

      &:focus {
        box-shadow: none;
      }
    }
  }

  input[type="text"] {
    width: 400px;
  }

  &_button-grup {
    .delivery-option {
      color: #00adee;
    }

    .btn {
      &.beach-view {
        margin-left: 16px;
      }

      .icon {
        height: 16px;
        margin-left: 10px;
        width: 16px;
      }
    }

    .kapture-blue, .btn-primary {
      .icon {
        stroke: #fff;
      }
    }

    .save-option {
      z-index: 2;
      justify-content: center;
      border: 1px solid #fff;
      border-radius: 7px;
      align-items: center;
      position: absolute;
      background-color: #fff;
      padding: 1rem;
      top: 42px;
      display: none;
      width: 150px;
    
      &_content {
        width: 100%;
        position: relative;
      }
    
      .file-types {
        margin-bottom: 20px;
        margin-left: 1px;

        a {
          margin-left: 1px;
        }
      }

      button {
        margin-left: 1px;
      }
    }

    .schedule-option, .download-option {
      z-index: 2;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: #fff;
      padding: 1rem;
      top: 42px;
      display: none;
    
      &_content {
        width: 100%;
        position: relative;
      }
    
      .schedule-options, .file-types {
        margin-bottom: 40px;
      }
    }
    
    .schedule-option {
      padding-top: 2rem;
      right: 0;
    }
    
    .download-option {
      width: 180px;
    }

    &.show-schedule-option {
      .schedule-option {
        display: block;
      }
    }
    &.show-download-option {
      .download-option {
        display: block;
      }
    }
  }

  .show-save-option {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);

    .save-option {
      display: block;
    }

    .standard-report-item {
      display: none;
    }
  }
}

.btn.form-custom-radio {
  border: 1px solid #ccc;
  &.selected {
    border-color: red !important;
    border-width: 2px;
  }
}

.schedule-option_content_title {
  color: #00558D;
  cursor: pointer;

  .icon {
    margin-right: 5px;
    stroke: #00558D;
    stroke-width: 4px;
  }
}

.custom-report_card {
  .schedule-option {
    display: none;
  }

  .t-item {
    display: block;
  }

  &.show-schedule-option {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);

    .schedule-option {
      display: block;
    }

    .t-item {
      display: none;
    }
  }
}

.report-header {
  input[type="text"]{
    border-radius: 0;
    border-bottom: 1px solid #91918A;
    color: #91918A;
  }
  .icon.edit {
    stroke: #91918A;
    margin-top: 16px;
  }
  
  .kapture-blue {
    margin: 0 10px;
  }

  .schedule-option_content {
    .icon {
      display: none;
    }
  }
}

.custom-report-builder {
  width: 1024px;
  margin: 0 auto;
  min-height: 100%;
}

.custom-reports-list_table-wrapper .icon {
  width: 1.25rem;
}

#reports-tbody {
  .icon.sand-clock {
    display: none;
  }
  
  .generating {
    .icon {
      display: none
    }
    padding: 10px;
    display: inline-block;
    &:after, &:before {
      bottom: 0;
    }
  }
}
