
svg.icon {
	height: 1.25rem;
	fill: none;
	stroke: #141C24;
  stroke-width: 0.1rem;
  vertical-align: middle;
	width: 1.25rem;

  &.sm {
    height: 14px;
    width: 14px;
  }

  &.md {
    height: 32px;
    width: 32px;
  }

  &.large {
    height: 64px;
    width: 64px;
  }

  &.x-large {
    height: 96px;
    width: 96px;
  }

  &.thin {
    stroke-width: 0.025rem;
  }

  &.bold {
    stroke-width: 0.15rem;
  }

  &.blue {
    stroke: blue;
  }

  &.orange {
    stroke: #ff6600;
  }

  &.up {
    rotate: 180deg;
  }

  &.right {
    rotate: 270deg;
  }

  &.left {
    rotate: 90deg;
  }
}

i.icon-dot {
  border-radius: 50%;
  display: inline-block;
  height: 0.75rem;
  margin-right: 5px;
  vertical-align: middle;
  width: 0.75rem;

  &.red {
    background-color: rgb(228, 127, 144);
  }

  &.green {
    background-color: rgb(37, 215, 159);
  }

  &.grey {
    background-color: grey;
  }

  &.instagram {
    background-color: #E13B56;
  }

  &.google {
    background-color: #FF9900;
  }

  &.pixel {
    background-color: #00558D;
  }

  &.facebook {
    background-color: #00A9DF;
  }

  &.other {
    background-color: #141C24;
  }
  
  &.other-gray {
    background-color: #91918A;
    display: inline-block;
    margin-top: 10px;
  }
}

.media-type {
  svg.icon {
    stroke: #ccc;
  }
}

.table-view_cell svg, .ajax-sortable-target svg {
  width: 12px;
  stroke: #91918A;
}

.google-keywords-table th a{
  margin-right: 5px;
}

.delete-info-entry{
  float: right;
  svg {
    height: 12px;
  }
}
