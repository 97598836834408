.content_requests {
    .main-container_primary-panel-wrapper {
        width: 100%;
    }

    .main-container_primary-panel {
        border-radius: 0;
        padding: 0;
    }
}

.request-type {
    @extend .d-flex;
    margin-bottom: 2rem;

    &_item {
        background-color: #fff;
        border-radius: 1rem;
        height: 328px;
        margin-right: 24px;
        padding: 16px;
        transition: all 0.35s ease;
        width: 328px;

        &_image-container {
            background-color: #ccc;
            border-radius: 0.85rem;
            display: block;
            height: 180px;
            margin-bottom: 1rem;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        &_title {
            font-size: 16px;
            color: #00558D;
            transition: all 0.35s ease;

            .icon {
                height: 14px;
                margin-left: 8px;
                stroke: #00558D;
                width: 14px;
            }
        }

        &_info {
            color: #141C24;
            margin-bottom: 0;
            transition: all 0.35s ease;
        }

        &:hover {
            background-color: #00558D;

            .request-type_item {
                &_title, &_info {
                   color: #fff;

                   .icon {
                       stroke: #fff;
                    }
                }
            }
        }
    }
}

.request-form-back {
    .back {
        font-size: 1rem;
    }

    .icon {
        stroke: #00558D;
        stroke-width: 0.25rem;
    }
}

.request-form {
    color: #91918A;
    margin: 0 auto;
    padding-bottom: 110px;
    width: 504px;

    &_header {
        margin-bottom: 52px;

        p {
            margin-bottom: 0;
        }
    }

    .page-title {
        font-size: 32px;
        margin-bottom: 0.75rem;
    }

    &_block {
        background-color: #fff;
        border: 1px solid #D0CBBD;
        border-radius: 0.5rem;
        box-shadow: 0 0 0.5rem #eee;
        margin-bottom: 52px;
        padding: 24px;

        label {
            display: block;
            font-size: 12px;
            font-weight: 600;
            color: #141C24;
            margin-bottom: 15px;
        }

        .select-content-form-filter_item, &.select-social-content {
            .dropdown {
                position: relative;
                &::after {
                    border-radius: 2px;
                    content: " ";
                    position: absolute;
                    right: 20px;
                    height: 14px;
                    width: 14px;
                    border-style: solid;
                    border-color: #535657;
                    border-width: 3px 3px 0 0;
                    transform: rotate(45deg);
                    top: 11px;
                    transition: all 0.35s ease;
                }

                &.show {
                    &::after {
                        transform: rotate(135deg);
                    }
                }
            }

            .btn.dropdown-toggle {
                display: block;
                width: 100%;
            }
        }

        &.select-property-content {
            border-color: #00558D;
            padding: 8px 0;

            .filter-option-inner {
                padding-top: 4px;
            }
        }

        .btn {
            padding-left: 24px;
            padding-right: 24px;
        }

        &.select-social-content {
            border-color: #F86A8D;
            padding: 16px 0;

            label {
                padding-left: 24px;
            }

            .dropdown-menu {
                margin-top: 19px;
            }
        }
    }

    .new_content_request {
        padding-top: 5px;

        .btn  {
            width: 240px;
        }
    }
  
    textarea {
      background-color: #fff;
      border: 1px solid #ccc;
    }

    .dropzone {
      border: 1px dashed #ccc;
    }

    .share-content-request {
      position: relative;

      label {
        font-size: 12px;
        font-weight: 500;
        color: #91918A;
        margin-bottom: 10px;
      }
      strong {
        color: #333;
      }
      .select2-container {
        border: 1px solid #ccc;
        border-radius: 5px;
      }
    }

    .btn-secondary {
        height: 42px;
        width: 240px;
    }
}

.saved-custom-reports,  .scheduled-reports {
    .table-view_header {
        justify-content: space-between;
        padding: 16px 48px;
    }

    .property-type, .property-date-range, .property-download-option, .property-schedule-freq {
        width: calc((100% - 210px) / 4);
    }

    .property-action, .property-pdf-edit, .property-pdf-delete {
        text-align: center;
        width: 70px;
    }

    .table-view_row-container {
        height: auto;
        max-height: 540px;
        overflow: auto;

        .table-view_row {
            justify-content: space-between;
            padding: 16px 48px;
        }
    }

    .total-items {
        padding: 9px 40px;

        p {
            margin-bottom: 0;
        }
    }
}

.request-hostory {
    .section-title {
        font-size: 16px;
    }

    .table-view_header, .table-view_row {
        padding: 16px 48px;
    }

    .total-items {
        padding: 9px 40px;
    }
}

.request-hostory_list {
  .table-view_cell {
    text-align: left;
    width: 20%;

    &.property-time {
      width: 50%;
    }
  }

  .table-view_row-container {
    height: 500px;
  }
}

.request-information {
  position: relative;

  &_desc {
    position: absolute;
    background-color: #525657;
    border-radius: 5px;
    color: #fff;
    display: none;
    padding: 10px 12px;
    left: 15px;
    top: 25px; 
    width: 350px;
  }

  &:hover {
    .request-information_desc {
      display: inline-block;
    }
  }
}

