.site-header {
  @extend .navbar, .navbar-expand-lg, .navbar-light, .bg-light;

  height: 72px;
  padding-left: 12px;

  &_left-section, &_right-section {
    @extend .d-flex, .align-items-center;
  }

  &_left-section {
    .select-property {
      height: 48px;

      .bootstrap-select {
        background-color: #F6F3EE;
        border-radius: 6px;
      }

      .bootstrap-select, .dropdown-toggle {
        height: 100%;
        padding: 0;
      }

      .bootstrap-select {
        background-color: #fff;
      }
    }

    .filter-option-inner-inner {
      margin-top: 0;
    }
  }

  &_right-section {
    .icon {
      height: 24px;
      width: 24px;
    }
  }

  .date-range-picker {
    @extend .form-inline, .d-flex, .align-self-center, .ml-auto;

    margin-right: 48px;

    .icon {
      margin-right: 0.5rem;
      stroke: #91918A;
    }

    label, input {
      color: #91918A;
    }
  }
  
  .user-profile {
    @extend .text-end !optional;
  }

  .navbar-brand {
    margin-right: 126px;

    /*
    img {
      height: 36px;
    }
    */
  }
}

.navbar {
  justify-content: space-between;
  margin-bottom: 0;

  &.bg-light {
    background-color: #00558D !important;
  }

  .user-profile {
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }

    .icon.right {
      height: 1rem;
      stroke: #afaeac;
      stroke-width: 0.15rem;
      transition: all 0.35s ease;
      width: 1rem;
    }

    &.show {
      .icon.right {
        transform: rotate(90deg);
      }
    }

    .dropdown-menu {
      background-color: transparent;
      border: 0;
      margin: 0.2rem -1rem 0 0;
      
      a {
        background-color: #fff;
        border-color: #cbcbbf;
        border-style: solid;
        border-width: 0 1px 1px;
        display: block;
        font-size: 0.75rem;
        padding: 0.5rem 1rem;
        transition: all 0.35s ease;

        .icon {
          margin-right: 1rem;
        }

        &:last-child {
          border-radius: 0 0 0.5rem 0.5rem;
        }

        &:hover {
          color: #00558D;

          .icon {
            stroke: #00558D;
          }
        }
      }
    }
  }

  .nav-link {
    padding: 1px 0;
  }

  .nav-link, .nav-link:hover {
    color: #141C24 !important;
  }

  .current-user-name {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
  }

  .user-profile-drop-dropdown {
    align-items: center;
    display: flex;

    .user-avatar-wrapper {
      align-items: center;
      border-radius: 50%;
      display: flex;
      height: 48px;
      justify-content: center;
      margin-right: 16px;
      overflow: hidden;
      width: 48px;
    }
  }
}

.notification {
  position: relative;
  margin-right: 20px;

  &.new {
    &::after {
      background-color: #00A9DF;
      border: 2px solid #fff;
      border-radius: 50%;
      content: " ";
      display: inline-block;
      height: 0.75rem;
      position: absolute;
      right: -0.12rem;
      top: 0.2rem;
      width: 0.75rem;
    }

    svg.icon.notification-bell {
      stroke: #00558D;
    }
  }

  svg.notification-bell  {
    height: 22px;
    width: 22px;
    stroke: #535657;
    stroke-width: 2px;

    &:hover {
      cursor: pointer;
    }
  }

  .dropdown-toggle {
    height: 30px;
    display: inline-block;

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    background-color: #00558D;
    border-radius: 16px;
    color: #fff;
    padding: 40px 32px 80px;
    width: 310px;

    .carousel-item {
      &_title {
        font-size: 16px;
        margin-bottom: 16px;
      }

      &_info, strong {
        font-size: 12px;
        font-weight: normal;
      }

      ul, ol {
        margin: 0;
        padding: 0 0 0 12px;
        font-size: 12px;
        font-weight: normal;

         li {
          margin-bottom: 6px;
         }
      }

      &_link-button {
        background-color: #fff;
        border-radius: 16px;
        color: #00558D;
        display: inline-block;
        font-size: 12px;
        padding: 0.5rem 1rem;
      }
    }

    .carousel-indicators {
      bottom: -2.15rem;
      margin-bottom: 0;

      & > li {
        border-radius: 50%;
        height: 0.5rem;
        width: 0.5rem;

        &.active {
          border: 1px solid #00A9DF;
        }
      }
    }

    .carousel-control-prev, .carousel-control-next {
      background-color: #00A9DF;
      border-radius: 50%;
      bottom: 36px;
      cursor: pointer;
      height: 32px;
      top: auto;
      width: 33px;

      .icon {
        height: 1rem;
        stroke: #fff;
        stroke-width: 0.25rem;
        width: 1rem;
      }
    }

    .carousel-control-prev {
      left: 32px;
    }

    .carousel-control-next {
      right: 32px;
    }
  }
}
