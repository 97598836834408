@media only screen and (min-width: 700px) {
    body {
        .site-header {
            padding-left: 12px;
            padding-right: 32px;

            &_right-section {
                /*.dropdown.notification {
                    display: none;
                }*/
            }

            .navbar-brand {
                margin-right: 48px;
            }

            &_left-section .select-property {
                width: 240px;
            }
        }

        .notification {
            margin-right: 20px;
        }

        .sk-login_welcome-graphics {
            display: none;
        }

        .sk-login-wrapper .sk-login_form-wrapper {
            width: 100%;

            .sk-login_form {
                margin: 0 auto;
            }
        }

        .search-and-notification {
            align-items: center;
            display: flex;
        }

        .main-container {
            &_left-panel {
                width: 72px;

                .main-nav > li span {
                    display: none;
                }
            }
            
            &_right-panel {
                width: calc(100% - 72px);
            }

            &_primary-panel {
                margin-left: 24px;
                width: 1032px;
            }
        }

        .footer {
            margin: 0 24px;
        }
    }
}

@media only screen and (min-width: 800px) {
    body {
        .main-container {
            &_left-panel {
                width: 144px;
        
                .main-nav > li {
                    span {
                        display: inline-block;
                        font-weight: 300;
                    }

                    &:hover, &.active {
                        span {
                            font-weight: 600;
                        }
                    }
                }
            }
            
            &_right-panel {
                width: calc(100% - 144px);
            }

            &_primary-panel {
                margin-left: 40px;
            }
        }

        .footer {
            margin: 0 40px;
        }
    }
}

@media only screen and (min-width: 1080px) {
    body {
        .site-header {
            padding-left: 24px;
            padding-right: 59px;

            &_left-section .select-property {
                width: 328px;
            }
        }

        .bootstrap-select {
            width: 328px;
        }

        .search-and-notification {
            .dropdown.notification {
                display: none;
            }
        }

        .site-header_right-section {
            .dropdown.notification {
                display: block;
            }
        }

        .notification {
            margin-right: 52px;
        }

        .sk-login_welcome-graphics {
            display: block;
        }

        .sk-login-wrapper .sk-login_form-wrapper {
            width: 456px;
        }
        
        .main-container {
            &_right-panel {
                width: calc(100% - 144px);
            }

            &_primary-panel {
                margin-left: 112px;
            }
        }

        .footer {
            margin: 0 112px;
        }
    }
}

@media only screen and (min-width: 1440px) {
    body {
        .site-header {
            padding-right: 59px;

            .navbar-brand {
                margin-right: 126px;
            }

            .date-range-picker {
                margin-right: 71px;
            }
        }

        .main-container {
            &_primary-panel {
                margin: 0 auto;
            }
        }

        .footer {
            margin: 0 auto;
        }
    }
}