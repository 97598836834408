// Place all the styles related to the form_submissions controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

#recommended-package {
  .message-container {
    &.success, &.error {
      font-size: 20px;
      height: 80%;
      width: 90%;
      position: absolute;
      z-index: 2;
      background: #fff;
      padding: 10px;
      top: 50px;
    }

    &.success {
      color: green;
    }

    &.error {
      color: red;
    }
  }
}
