.navbar {
  .nav-link {
    color: $white;  
    
  }

  .page-title {
    color: $white;
    font-size: 24px;
    font-weight: normal;
    margin-left: 8rem;
  }

  h4, .h4 {
    font-size: 24px;
    font-weight: 600;
  }

  .fb-connect{
    text-transform: uppercase;
    color: #aaa;
    font-size: 1em;
    display: inline-block;
    padding: 0 20px 0 0;
    border-right: 1px solid #aaa;

    img {
      height: 26px;
    }
  }

  .nav-item.dropdown{
    display: inline-block;
  }
}

.page-title {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.sk-left-panel {
  .form-control-plaintext {
    color: $white;
  }
}

.table {
  font-size: 0.8rem;
}

.fb-connect{
  text-align: center;
  
  .btn {
    background-color:  #3B5998;
    color: #fff;
  }
}
