.cards {
  display: flex;
  margin: 0 -15px;

  &.with-section-title {
    margin-top: 35px;
  }

  &.property-cards {
    margin-bottom: 20px;

    .card {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      width: 15%;

      &.large {
        width: 40%;
      }
    }
  }
}

.card {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  flex-grow: 1;
  margin: 0 15px;
  padding: 17px 20px;
  position: relative;

  &.card-with-btn {
    padding: 17px 10px;
    .card-with-btn-content {
      padding: 0 12px;

      .progress-amount {
        font-size: 22px;
      }
    }
  }

  &_title {
    font-size: 14px;
    font-weight: 600;
    position: relative;
    text-transform: uppercase;
    z-index: 1;

    &.slick {
      font-size: 10px;
      min-height: 30px;
    }
  }

  .section-title {
    left: 0;
    position: absolute;
    top: -25px;

    &.inner {
      top: 0;
      position: relative;
      margin-bottom: 10px;
    }
  }

  .progress-amount {
    font-size: 36px;
    font-weight: 600;
    line-height: 46px;
  }

  &.center {
    text-align: center;
  }

  &.large {
    width: 40%;
  }

  &.medium {
    width: 30%;
  }

  .icon {
    height: 34px;
    width: 34px;

    &.shifted {
      left: calc(50% - 17px);
      position: absolute;
      top: -15px;
    }

    &.sk-icon, &.time {
      height: 37px;
      width: 37px;
    }

    &.fav-star {
      height: 50px;
      position: absolute;
      right: -2px;
      top: -2px;
      width: 50px;
    }

    &.tini {
      height: 12px;
      position: absolute;
      right: 5px;
      top: 5px;
      width: 12px;
    }

    &.tick {
      height: 15px;
      margin-right: 8px;
      width: 15px;
    }

    &.total-impressions, &.total-clicks, &.average-ctr, &.ad-spend, &.impressions, &.clicks, &.dollar {
      height: 38px;
      width: 38px;
    }
  }

  .progress-percent {
    margin: 15px 0;
    overflow: hidden;
    .ldBar-label {
      display: none;
    }

    .ldBar{
      width: 100% !important;
    }
  }

  .btn-rounded {
    border-radius: 18px;
    box-shadow: none;
    height: 24px;
    font-size: 12px;
    line-height: 12px;
    margin: 0 auto;
    min-width: 200px;
    text-transform: capitalize;
  }

  .package-logo-container {
    margin: 15px 0;
  }

  &_value {
    color: #00aded;
    font-size: 44px;
    font-weight: 400;
    margin-top: 20px;
  }

  &_icon {
    background-color: #eee;
    border: 0.1rem solid #ccc;
    color: red;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.25rem 0.75rem;
  }

  &_info {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &.leads {
    position: relative;

    a {
      display: none;
      position: absolute;
      bottom: 0;
      background: #aaa;
      width: 100%;
      text-align: center;
      color: #fff;
      padding: 1px;
      font-size: 11px;

      small {
        display: block;
      }
    }

    &.grey {
      h2, p {
        color: #bbb;
      }

      h2 {
        position: relative;

        &:after {
          content: '*';
          font-size: 25px;
          top: 8px;
          right: -6px;
          position: absolute;
        }
      }

      a {
        display: block;
      }
    }
  }

  &.summary-card-with-graph {
    .summary-card-with-graph_info {
      width: 60%;

      .card_title {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 3px;
      }
    }
  }

  .progress-status {
    padding: 15px 0;
  }

  .components {
    p {
      padding: 5px;
      &:nth-child(odd){
        background-color: #eee;
      }
      span {
        display: inline-block;
        &:first-child {
          width: 50%;
          text-align: left;
          float: left;
          margin-left: 50px;
          text-transform: capitalize;
        }
      }
    }
  }
}

.conversions .cards {
  justify-content: space-between;
}
