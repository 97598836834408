.transparent-btn {
  background-color: transparent;
  border: 0.15rem solid #000;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  transition: all 0.35s ease;
  &:hover {
    border-color: #00adef;
    color: #00adef;
    text-decoration: none;
  }
}
.line-btn {
  background-color: transparent;
  border-bottom: 0.15rem solid #000;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.3 0;
  text-transform: uppercase;
  transition: all 0.35s ease;
  &:hover {
    border-color: #00adef;
    color: #00adef;
    text-decoration: none;
  }
}
.sk-transparent-button {
  background-color: transparent;
  border: 0.1rem solid #6a86a7;
  color: #6a86a7;
  margin: 1rem 0;
  padding: 0.5rem 0;
  &:hover {
    color: $white;
    text-decoration: none;
  }
}
.clear-button-style {
  background-color: transparent;
  border: none;
}
.btn.dashboard-content-req-btn {
  padding: 6px 60px !important;
  border-radius: 10px;
}
