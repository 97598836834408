.home-tab-visibility-handler {
  .home-tab-chart-control-link {
    display: inline-block;
    padding: 10px;
    border: 1px solid blue;
    border-radius: 20px;
  }

  .nav-tabs-common {
    visibility: visible;
    height: auto;
  }

  .nav-tabs-fb, .nav-tabs-insta {
    visibility: hidden;
    height: 0;
  }

  &.fb {
    .nav-tabs-fb {
      visibility: visible;
      height: auto;
    }

    .nav-tabs-common, .nav-tabs-insta {
      visibility: hidden;
      height: 0;
    }

    .home-tab-chart-control-link {
      &.fb {
        border: 1px solid gray;
      }

      &.insta {
        border: 1px solid blue;
      }
    }
  }

  &.insta {
    .nav-tabs-insta {
      visibility: visible;
      height: auto;
    }

    .nav-tabs-common, .nav-tabs-fb {
      visibility: hidden;
      height: 0;
    }

    .home-tab-chart-control-link {
      &.fb {
        border: 1px solid blue;
      }

      &.insta {
        border: 1px solid gray;
      }
    }
  }
}

// temp styling for search
.select-property {
  border: 0 !important;
}
