.social-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  .social-handle-linked-grid {
    width: 328px;

    .carousel-inner {
      min-height: 450px;
    }

    &_slider {
      margin-top: 1rem;
    }
  }

  &_tabs {
    width: 679px;
  }
}

.social-handle-linked-grid {
  &_list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    &_item {
      align-items: center;
      background-color: #D0CBBD;
      border-color: #fff;
      border-style: solid;
      border-width: 0 1px 1px 0;
      display: flex;
      height: 108px;
      justify-content: center;
      width: 108px;

      &:last-child {
        border-right: none;
      }

      img, video {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  .carousel-control-wrapper {
    align-items: center;
    display: flex;

    .carousel-control-prev, .carousel-control-next, .carousel-indicators {
      position: static;
    }
  
    .carousel-control-prev, .carousel-control-next {
      background-color: #fff;
      border-radius: 50%;
      height: 2rem;
      opacity: 1;
      position: relative;
      transition: all 0.35s ease;
      width: 1.85rem;

      .icon {
        height: 0.85rem;
        stroke-width: 0.18rem;
        width: 0.85rem;
      }

      &:hover {
        background-color: #fff;
        cursor: pointer;
      }
    }

    /*.carousel-control-prev {
      &::before {
        transform: rotate(45deg);
      }
    }*/

    button {
      border: none;
    }

    .carousel-indicators {
      margin: 0 16px;

      button {
        background-color: #D0CBBD;
        border-radius: 50%;
        height: 12px;
        margin: 0 8px;
        width: 12px;

        &:active {
          background-color: #00A9DF;
        }
      }
    }
  }
}

.local-packages {
  .nav-tabs .nav-item {
    width: 160px;
  }

  .nav-item {
    &.impressions {
        border-color: #00A9DF;
    }

    &.engagement {
        border-color: #8CD4E6;
    }

    &.reach {
        border-color: #F6DA82;
    }

    &.followers {
        border-color: #FF9900;
    }
  }

  .active-tab-impressions {
    .nav-item.impressions {
        background-color: #fff;
        margin-top: 0;
        padding-top: 12px;
        width: 172px;

        &::after {
            background-color: #fff;
        }
    }

    .tab-content-wrapper {
        border-color: #00A9DF;
    }
  }

  .active-tab-engagement {
    .nav-item.engagement {
        background-color: #fff;
        margin-top: 0;
        padding-top: 12px;
        width: 172px;

        &::after {
            background-color: #fff;
        }
    }

    .tab-content-wrapper {
        border-color: #8CD4E6;
        position: relative;

        .engagement-rate {
          position: absolute;
          right: 10px;

          strong {
            display: block;
            margin-top: 5px;
            text-align: right !important;
          }
        }
    }
  }

  .active-tab-reach {
    .nav-item.reach {
        background-color: #fff;
        margin-top: 0;
        padding-top: 12px;
        width: 172px;

        &::after {
            background-color: #fff;
        }
    }

    .tab-content-wrapper {
        border-color: #F6DA82;
    }
  }

  .active-tab-followers {
    .nav-item.followers {
        background-color: #fff;
        margin-top: 0;
        padding-top: 12px;
        width: 172px;

        &::after {
            background-color: #fff;
        }
    }

    .tab-content-wrapper {
        border-color: #FF9900;
    }
  }
}

/**Need to re work**/
.top-performers {
  margin-bottom: 32px;

  &_title {
    font-size: 24px;
    color: #141C24;
    margin-bottom: 16px;
    font-weight: 500;
  }

  ul {
    margin-top: 0;
    padding-top: 0;

    & > li {
      margin-top: 0;
    }
  }
}

.stories-section {
  margin-bottom: 62px;
}

.social-page-section-header {
  margin-bottom: 16px;

  &_title {
    color: #141C24;
    font-size: 24px;
    font-weight: 500;
  }

  .icon {
    height: 22px;
    margin-right: 8px;
    stroke: #141C24;
    vertical-align: baseline;
    width: 22px;
  }

  &.gbp-title-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.cards {
  .card {
    border-radius: 0.5rem;
    outline: 1px solid #141C24;
    padding: 0;
    width: 33.33%;

    .card-header {
      font-size: 16px;
      padding: 18px 28px 18px 32px;
    }

    .card-container {
      padding: 13px 28px 13px 32px;

      &.activity {
        padding: 13px 0px 13px 32px;

        .card-container_item {
          display: inline-block;
          &:nth-child(2) {
            margin: 0 20px;
          }
        }
      }
    }
  }
}

.meta-ads {
  .card {
    .preview-container {
      padding: 0.5rem 0;
      text-align: center;
    }
  }
}

.post-card {
  border-radius: 0.5rem;
  outline: 1px solid #141C24;
  padding-bottom: 1rem;
  width: 33.33%;

  .info {
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    color: #000;
  }

  &_image-and-info-container {
    border: 1px solid #D0CBBD;
    border-radius: 0 0 12px 12px;
    margin: 0 16px 16px;
  }

  &_image-and-information {
    font-size: 12px;
    margin-bottom: 0;
    padding: 16px;
    background-color: #f6f3ee;
    border-radius: 0 0 11px 11px;
  } 

  &_image-container {
    border: 4px solid #D0CBBD;
    position: relative;
    margin-bottom: 0;

    img, video {
      border-radius: 0;
      display: block;
    }

    .media-type {
      align-items: center;
      background-color: rgba(145, 145, 138, 0.8);
      border-radius: 0.5rem;
      bottom: 0.25rem;
      display: flex;
      justify-content: center;
      left: 0.25rem;
      position: absolute;
      width: 3.25rem;
      height: 3.25rem;

      .icon {
        margin: 0;
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  }

  a {
    color: #141C24;
  }

  &.instagrammedium {
    border-top: 10px solid #F86A8D;
  }
  
  &.facebookpost {
    border-top: 10px solid #17568D;
  }
}

.post-card, .meta-ads-scores {
  .top-posts_summary {
    margin: 24px 16px 18px;
    padding: 0;
  }

  .top-posts {
    &_summary {
      padding: 0 16px;

      &_item {
        @extend .d-flex, .justify-content-between;
        position: relative;
        margin-bottom: 16px;

        &_label, &_value {
          background-color: #fff;
          font-size: 0.75rem;
          position: relative;
          width: auto;
          z-index: 1;
        }

        &_label {
          padding-right: 1rem;
        }

        &_value {
          color: #141C24;
          font-size: 16px;
          padding-left: 1rem;
        }

        &::after {
          border-bottom: 1px solid #D0CBBD;
          bottom: 5px;
          content: " ";
          display: block;
          left: 0;
          position: absolute;
          height: 0;
          right: 0;
        }

        &_label {
          color: #535657;
          text-transform: capitalize;
        }

        &:last-child {
          .top-posts_summary_item_label {
            color: #141C24;
          }

          .top-posts_summary_item_value {
            color: #141C24;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.info-container {
  margin: 16px 32px;

  .icon {
    height: 18px;
    margin-right: 5px;
    width: 18px;
  }

  .info {
    display: flex;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;

    &_text {
      width: calc(100% - 30px);
      word-wrap: break-word;
    }
  }

  .date-stamp {
    font-size: 12px;
    line-height: 18px;
    padding-left: 26px;
    margin-bottom: 0;
  }
}

.row.sk-main-container_header {
  margin-top: 2rem;
}

.line-chart-link {
  border-radius: 10px 10px 0 0;
  display: block;
  font-size: 12px;
  padding: 16px 0 16px 13px;
}

.chart-handler {
  & > a {
    border-radius: 2rem;
    border: 2px solid;
    color: #141C24;
    cursor: pointer;
    display: inline-block;
    padding: 0.5rem 1rem;
    text-align: center;
    margin: 0 1rem;

    &.fb, &.insta, &.engagement-rate {
      border-color: #ccc;

      .eye-close {
        display: inline-block;
      }

      .eye {
        display: none;
      }
    }

    &.fb.active {
      border-color: rgba(54, 162, 235, 0.9);

      .eye-close {
        display: none;
      }

      .eye {
        display: inline-block;
      }
    }

    &.insta.active {
      border-color: rgb(255, 99, 132);

      .eye-close {
        display: none;
      }

      .eye {
        display: inline-block;
      }
    }
    &.engagement-rate.active {
      border-color: rgba(54, 162, 235, 0.9);

      .eye-close {
        display: none;
      }

      .eye {
        display: inline-block;
      }
    }

    .icon {
      height: 1rem;
      margin: 0 0.5rem;
      stroke: #141C24;
      width: 1rem;
    }
  }
}

.gbp-wrapper {
  .cards .card .card-header {
    background-color: #FF9900;
  }
}

.gbp-month-selector {
  @extend .form-inline, .d-flex, .align-self-center;

  .icon {
    margin-right: 1rem;
    stroke: #91918A;
  }

  .month-select {
    border: 1px solid #ccc;
    padding: 0.5em 1em;
    background-color: #fff;
    border-radius: 5px;
  }

  &.active {
    .icon {
      stroke: #00558D;
    }
  }
}

.social-content_tabs_wrapper {
  .engagement-rate {
    display: none;
    .engagement-rate-total, .engagement-rate-fb, .engagement-rate-insta {
      display: none;
      &.visible {
        display: inline-block;
      }
    }
  }

  &.active-tab-engagement {
    .engagement-rate {
      display: inline-block;
    }
  }

  .chart-handler {
    position: relative;
  }

  .follower-growth {
    display: none;
    position: absolute;
    left: 45px;
  }

  &.active-tab-followers {
    .follower-growth {
      display: inline-block;
    }
  }
}

span.direction-requests{
  width: 107px !important;
  display: inline-block;
}

.local-packages {
  &_title-bar {
    @extend .d-flex, .justify-content-between;

    .download-link {
      margin-bottom: 0;
    }
  }
}
