/* custom bar charts css */

.bar {
  text-align: center;
  display: inline-block;

  .tick {
    width: 60px;
    margin-bottom: 10px;
    background-image: linear-gradient(#0280ba, #7fd3f6);
    display: block;
    margin: 0 auto;
    border-radius: 4px;
  }

  .label {
    height: 20px;
    em {
      display: block;
      color: #ccc;
    }
  }
}

.campaign-breakdown {
  .bar {
    .tick {
      width: 100px;
    }
  }
}
