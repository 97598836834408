/* enhancements page */
.select-ad-set-id {
  border-radius: 5px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  .select2.select2-container {
    width: 100% !important;
  }

  .css-1s2u09g-control {
    border: none;
  }
  
  .css-qc6sy-singleValue, #reach-select-2-listbox {
    font-size: 14px !important;
  }
}

#campaign-detail {
  table tbody {
    font-weight: 500;
    font-size: 14px;

    td {
      padding-top: 10px;
    }
  }
}

.enhancements-ad-set-campaign {
  td {
    width: 10%;

    &.adset-name {
      width: 50%;
    }

  }
}
