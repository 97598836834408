@media only screen and (max-width: 990px) {
  .we-measure {
    .navbar {
      margin-bottom: 0;

      .navbar-nav {
        align-items: center;
        background-color: #0888dae6;
        height: 100vh;
        padding-top: 4rem;
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;

        .nav-item {
          margin: 0 1rem;
        }

        .nav-link {
          color: $white;
          font-size: 24px;
          font-weight: 500;
          text-transform: uppercase;

          &:hover {
            color: #bbb;
          }
        }
      }
    }
  }

  .hihe-on-small-screen {
    display: none;
  }

  .navbar-toggler {
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
  }

  div.client-feedback_content.first {
    border: none;
  }

  /**responsive styles**/

  .banner-content {
    margin-top: 3rem;
    position: relative;
  }

  .what-we-do_image {
    display: block;
    margin: 0 auto;
    max-width: 80%;
  }
}

@media only screen and (max-width: 575px) {
  .what-we-do_info {
    padding: 50px 15px 0;
    text-align: center;
  }

  .statistic_values {
    .col-sm {
      border-bottom: 1px dashed #eee;
      margin-bottom: 20px;
      padding-bottom: 20px;

      .statistic_value {
        margin: 0;
      }
    }
  }

  .what-our-clients-say .our-client_info {
    margin-top: 4rem;
    position: static;
  }

  .what-our-clients-say .client-review {
    margin-top: 2rem;

    &::after {
      border-color: transparent transparent #00adef;
      bottom: auto;
      left: auto;
      right: 3rem;
      top: -2.8rem;
    }
  }

  .your-testimonial div {
    text-align: center !important;
  }

  .digital-marketing_info_btn-group {
    align-items: center;
    display: flex;
    flex-flow: column;
    margin: 3rem 0;

    .transparent-btn {
      margin: 0 0 3rem;
    }
  }

  .everything-you-need_image {
    display: block;
    margin: 0 auto;
  }

  .social-media-manage_image {
    max-width: 100%;
  }
}

@media only screen and (min-width: 990px) {
  .hihe-on-desktop {
    display: none;
  }

  .banner-content {
    margin-top: 150px;
    margin-bottom: 100px;
    position: relative;
    z-index: 9;
  }

  .digital-team_info {
    margin-right: 5rem;
    margin-top: 9rem;
  }

  .what-we-do_info {
    margin: 5rem 0 0 4rem;
  }
}
