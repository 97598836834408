.sk-main-container {
  background-color: #fff;
  overflow: auto;

  .page-title {
    margin: 1rem 0;

    span {
      border-bottom: 4px solid #828c98;
      display: inline-block;
      padding-bottom: 0.5rem;
    }
  }

  &_header {
    color: #004B70;
    margin-bottom: 1rem;

    &_date-picker {
      margin-left: 0.5rem;
      padding: 0 0.5rem;
      width: 74px;
    }

    &_select {
      border: 0.1rem solid #ccc;
      margin-left: 0.5rem;
      padding: 0 0.5rem;
    }
  }
}

.post-time-stamp {
  width: 7rem;
}

.post-info {
  color: #666;
  p {
    margin-top: 10px;
    overflow: hidden;
    @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: white);
  }
  &.google-ad {
    min-height: 222px;
    padding-top: 20px !important;
  }
}
.post-type {
  width: 5rem;
}
.post-target {
  width: 5rem;
}
.post-reach, .post-engagement {
  width: 14%;
  span {
    display: inline-block;
    width: 35px;
    text-align: right;
  }
}
.sk-dashboard-card-container {
  height: 20rem;
}

.sk-dashboard-info-container, .sk-dashboard-info-table{
  table thead {
    background-color: $LightSteelBlue;
    color: $white;
  }
}

.sk-dashboard-info-container {
  margin-top: 1.5rem;
  position: relative;
  .col-sm-6 {
    overflow: auto;
  }

  table thead {
    background-color: $LightSteelBlue;
    color: $white;
  }
}
.sk-dashboard-card-container {
  .d-flex {
    margin-bottom: 0.5rem;
  }
  .row-full {
    height: 19.5rem;
  }
  .row-half {
    height: 9.5rem;
  }
}
.dashboard-card {
  align-items: center;
  background-color: $white;
  box-shadow: 0 0 0.2rem #ccc;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 152px;
  justify-content: center;
  width: calc(25% - 0.5rem);
  &.chart-container {
    padding: 0 1rem;
    width: calc(50% - 0.5rem);
    #chart {
      width: 100%;
    }
  }
  &_value {
    color: #00aded;
    font-size: 44px;
    font-weight: 400;
  }
  &_icon {
    background-color: #eee;
    border: 0.1rem solid #ccc;
    color: red;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.25rem 0.75rem;
  }
  &_info {
    font-size: 1.1rem;
    font-weight: 300;
  }

  &.leads {
    position: relative;
    a {
      display: none;
      position: absolute;
      bottom: 0;
      background: #aaa;
      width: 100%;
      text-align: center;
      color: #fff;
      padding: 1px;
      font-size: 11px;
      small {
        display: block;
      }
    }
    &.grey {
      h2, p {
        color: #bbb;
      }
      h2 {
        position: relative;
        &:after {
          content: '*';
          font-size: 25px;
          top: 8px;
          right: -6px;
          position: absolute;
        }
      }
      a {
        display: block;
      }
    }
  }
}

.last-updated {
  font-weight: 600;
}

.cards.property-cards .card.content-requests {
  align-items: center;
  padding: 30px 40px;
  position: relative;

  .content-requests_summary {
    align-items: center;
    display: flex;
    flex-flow: row;
    height: 100%;
    justify-content: space-between;
    padding: 40px 0;
    width: 100%;

    .content-requests_summary_info {
      max-width: calc(100% - 90px);
    }
  }

  .btn {
    bottom: 30px;
    left: 40px;
    margin: 0;
    position: absolute;
  }
}

.google-analytics {
  .dashboard-card {
    width: calc(33% - 0.5rem);
  }
}
.three-column-card-container {
  .dashboard-card {
    width: calc(50% - 0.5rem);
  }
}
.sk-dashboard-info-table {
  padding-top: 1rem;
  &_image-container {
    background-color: #00c5f1;
    margin-right: 0.5rem;
    width: 4rem;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  &_post-info {
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .d-flex {
    align-items: center;
  }
  .progress {
    border-radius: 0;
    height: 0.75rem;
    margin-left: 0.5rem;
    width: calc(100% - 1rem);
    &.purple .progress-bar {
      background-color: #844684;
    }
  }
}
.date-picker-wrapper .footer {
  padding: 0;
}
#selectfrom, #selectto {
  width: 100px;
}

/* alert styling */
.flash.alert-danger, .flash.alert-success {
  position: absolute;
  width: 100%;
  top: 0;
  text-align: center;
  margin: 0;
  z-index: 999;
  &.hidden {
    display: none;
  }
}

/* modal */
.modal-dialog {
  &.dashboard_photos, &.content_requests, &.dashboard_occupancy, &.prospect_generator, &.dashboard_paid_ads{
    max-width: 800px;
  }

  &.ad-preview, &.ad-preview-insta {
    max-width: 570px; // iframe width 540 + 30 padding

    .modal-body {
      text-align: center;
      padding: 0 1rem;
    }

    .modal-header {
      border-bottom: none;
    }
  }

  &.ad-preview-insta {
    max-width: 370px; // iframe width 340 + 30 padding
  }
}

.welcome-modal {
  .modal-content {
    width: 640px;
    text-align: center;

    .modal-body {
      padding-top: 30px;

      h3 {
        margin: 20px 0;
        font-size: 25px;
        letter-spacing: 4px;
      }

      img {width: 250px;}

      .btn-primary {
        margin: 20px auto;
        display: block;
        height: 44px;
        font-size: 16px;
        background-color: $primaryBtn;
        border-color: $primaryBtn;
      }
    }
  }

  &.lead-generation-popup {
    .modal-body {
      padding: 100px;
    }

    p {
      font-size: 18px;
      margin: 30px 0;
      span {
        color: $primaryBtn;
      }
    }
  }

  &.accept-tos-popup {
    .accept-tos {
      width: 80% !important;
      padding: 10px;
      border-radius: 10px;
    }
  }
}

.loading-wrapper {
  position: fixed;
  text-align: center;
  top: 75px;
  width: auto;
  z-index: 999;
  left: calc(50% - 50px);

  img {
    height: 50px;
  }
}


.no-lead {
  .dashboard-card {
    h2, p {
      color: #bbb;
    }
    h2 {
      position: relative;
      &:after {
        font-size: 25px;
        top: 8px;
        right: -6px;
        position: absolute;
      }
    }
    a {
      display: block;
    }
  }
}

/* Dashboard V2 styles */
/*** Panel style ***/
.sk-v2 {
  display: flex;

  &_main-panel {
    max-height: calc(100vh - 80px);
    overflow: auto;
    padding: 25px;
    width: calc(100vw - 50px);
  }
}
/*** End Panel style ***/

.cards.welcome-message {
  margin-bottom: 25px;

  p {
    font-size: 18px;
    margin: 0;
  }

  .welcome-message_addressing {
    font-weight: 600;
  }
}

.property-cards {
  .title-and-status-wrapper {
    height: 55px;
  }
}

.card.card-with-btn.extra-padding {
  padding: 30px 40px;
}

.dashboard-overview {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  &_header {
    margin-bottom: 25px;

    .section-title {
      margin-bottom: 0;
    }

    .view-more {
      text-transform: capitalize;

      &:hover {
        text-decoration: none;
      }

      .icon {
        height: 15px;
        width: 15px;

        &.more-arrow {
          background-position: -63px -17px;
          vertical-align: middle;
        }
      }
    }
  }

  &_graph {
    margin-top: 15px;
  }

  & > section {
    width: calc(50% - 15px);
  }

  .card {
    padding: 12px;
    width: 23.33%;

    &.medium {
      width: 30%;
    }

    &.top-performing {
      padding: 0;
      position: relative;

      img {
        border-radius: 8px;
        object-fit: cover;
        width: 100%;
      }

      .top-performing-ad {
        text-align: center;
        p {
          margin: 0 10px;
        }

        img {
          width: 88%;
          margin: 6% auto;
          display: block;
        }
      }

      .top-performing_title {
        bottom: -20px;
        color: #888;
        left: 0;
        position: absolute;
        text-align: center;
        width: 100%;
      }

      .no-found {
        color: #777;
        padding-top: 20px;
        text-align: center;

        img {
          display: inline-block;
          width: auto;
        }

        &_info {
          font-size: 14px;
          margin: 12px 0;
          text-transform: uppercase;
        }

        a {
          margin-bottom: 12px;
        }
      }

      .only-text {
        padding: 20px;
        text-align: center;
      }
    }

    .text-gray {
      color: #888;
      font-size: 11px;
    }
  }

  &_value {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0;
  }
}
/*** End Cards style ***/

/***insights-and-recommend***/
.wrap-two {

  &_item {
    width: calc(50% - 15px);

    .post-card {
      width: 33.33%;
    }
  }
}

.wrap-four {
  &_item, .card {
    width: 25%;
  }
}

.card.total-reach, .card.follower-growth, .card.engagement, .card.reach {
  .icon.shifted {
    left: calc(50% - 16px);
  }
}

.insights-and-recommend {
  margin: 30px 0;

  .ad-area-chart-wrapper {
    height: 170px;
    margin-top: 10px;

    &.digital-ad {
      height: 250px;
    }

    &.with-traffic-driven {
      height: 204px;
    }
  }
}

.facebook-and-instagram-post {
  .dashboard-overview_header {
    margin-bottom: 0;
  }
}

/****Social Page UI****/

.insights-time-and-type {
  margin: 30px 0;
}

.facebook-and-instagram-post, .performing-posts  {
  width: 100%;

  &_list {
    flex-flow: row;
    justify-content: space-between;
    padding: 15px 0;
  }

  .post-card {
    width: 20%;
    position: relative;
    overflow: hidden;

    @include boosted-badge-container;
    max-width: 20rem;
  }
}

.post-card {
  background-color: #fff;
  border-radius: 5px;
  display: inline-flex;
  flex-flow: column;
  flex-grow: 1;
  justify-content: space-between;
  margin: 15px;

  &_image-container {
    margin-bottom: 10px;

    img {
      border-radius: 5px;
      object-fit: cover;
      width: 100%;
    }
  }

  .post-info {
    padding: 0 8px 15px;

    &_caption {
      margin: 10px 0;
    }

    .dale-and-link {
      margin-bottom: 10px;
    }

    &_date {
      font-size: 11px;
    }

    .icon {
      vertical-align: middle;
    }
  }

  .no-photo {
    padding: 10px;
  }

}

.google-ad-mpressions-summary {
  margin-top: 30px;
}


// responsive design
@media only screen and (max-width: 990px) {
  .dashboard-card_value {
    font-size: 2.5rem;
  }
}

// google analytics page
.traffic-driven-table {
  th small{
    text-transform: lowercase;
    display: block;
  }

  td {
    small {
      display: inline-block;
      margin-left: 10px;
      width: 50px;
      color: #888;
    }

    strong {
      display: inline-block;
      width: 3.5em;
    }
  }
}

#recommended-packages{
  min-height: 235px; // match to the area default chart height of 155px
}

.filter-dashboard-btn {
  margin-left: 10px;
}

.property-search-filter {
  position: relative;

  .btn.filter-dashboard-btn {
    background-color: #fff;
    border: 1px solid #00558D;
    color: #00558D;
    transition: all 0.35s ease;

    &.active {
      background-color: #00558D;
      border-color: 84b8d1;
      color: #fff;
    }
  }
}

.property-filter-card {
  border: 1px solid #00558D;
  padding: 32px 24px 50px;
  background-color: #fff;
  border-radius: 10px;
  display: none;
  left: 10px;
  position: absolute;
  top: 46px;
  width: 465px;

  &.show {
    display: block;
  }

  &_clear {
    border: none;
    cursor: pointer;
    text-decoration: underline;
  }

  .bootstrap-select {
    border: 1px solid #D0CBBD;
  }

  &_form {
    .select2-container {
      width: 100% !important;
    }

    label {
      display: block;
      margin-bottom: 15px;
    }
   
    .form-check {
      padding-left: 2.25rem;
      display: inline-block;
      label {
        margin-left: 5px;
      }
    }

    .form-check-input {
      border: none;
      height: 0;
      margin-top: 13px;

      &::before, &::after {
        border-radius: 50%;
        content: " ";
        height: 12px;
        left: 0;
        position: absolute;
        top: -0.8em;
        width: 12px;
      }

      &#property_alert_instagram {
        &::before {
            background-color: #fff;
            border: 1px solid #E13B56;
        }
        &:checked {
            &::after {
                background-color: #E13B56;
            }
        }
      }

      &#property_alert_google {
        &::before {
            background-color: #fff;
            border: 1px solid #FF9900;
        }
        &:checked {
            &::after {
                background-color: #FF9900;
            }
        }
      }

      &#property_alert_pixel {
          &::before {
              background-color: #fff;
              border: 1px solid #00A9DF;
          }
  
          &:checked {
              &::after {
                  background-color: #00A9DF;
              }
          }
      }

      &#property_alert_facebook {
        &::before {
            background-color: #fff;
            border: 1px solid #00558D;
        }

        &:checked {
            &::after {
                background-color: #00558D;
            }
        }
      }

      &#property_alert_other {
        &::before {
            background-color: #fff;
            border: 1px solid #141C24;
        }

        &:checked {
            &::after {
                background-color: #141C24;
            }
        }
      }
    }

    .form-group {
      margin-bottom: 15px;

      label {
        display: block;
      }

      .filter-option {
        .filter-option-inner-inner {
          display: block !important;
          font-weight: 400;
          font-size: 15px;
          margin-top: 4px;
        }
      }
    }

    .form-group.submit {
      text-align: center;
      margin-top: 3rem;
    }
  }

  .form-switch.instagram, .form-switch.other {
    padding-left: 20px;
  }

  .select2-selection__choice, .select2-selection__choice__remove, .select2-selection__choice__display {
    background-color: #fff !important;
  }

  .select2-selection.select2-selection--multiple {
    border: 1px solid #D0CBBD;
    margin-bottom: 24px;
    min-height: 36px;
    padding: 5px 8px;
    width: 100%;
  }

  #q_company_name, #company_name {
    border: 1px solid #D0CBBD;
    margin-bottom: 24px;
    min-height: 36px;
    padding: 5px 8px;
    width: 25%;
    background-color: transparent;
  }

  #company_name {
    width: 74%;
  }

  .select2-search__field {
    width: 350px !important;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    padding: 5px 24px 5px 16px;
    border-radius: 12px;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    border: none;
    bottom: 0;
    left: auto;
    padding: 0 12px 0 4px;
    right: 0;
  }

  .btn {
    padding: 5px 40px;
  }
}

.management-company-filter .select2-container {
  width: 74% !important;
  vertical-align: top;
}
